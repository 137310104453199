<template>
  <div class="app-wrapper">
    <navbar/>
    <div class="main-wrapper">
      <sidebar class="sidebar-container" />
      <app-main class="app-main-container"/>
    </div>
  </div>
</template>

<script>
import { AppMain, Navbar, Sidebar } from './components'

export default {
  name: 'AdLayout',
  components: {
    AppMain,
    Navbar,
    Sidebar
  },
}
</script>

<style lang="less" scoped>
  .app-wrapper {
    height: 100%;
    width: 100%;

    .main-wrapper {
      display: flex;
      flex-direction: row;
      height: calc(~"100vh - 50px");
    }

    .app-main-container {
      flex: 1;
      height: 100%;
    }
  }
</style>
