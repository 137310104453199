<template>
  <el-breadcrumb separator-class="el-icon-arrow-right">
    <el-breadcrumb-item />
    <el-breadcrumb-item v-for="item in breadcrumb" :key="item.name">
      {{ item.name }}
    </el-breadcrumb-item>
  </el-breadcrumb>
</template>

<script>
export default {
  name: 'bread-crumb',
  data() {
    return {
      breadcrumb: []
    }
  },
  computed: {
    currentRoutePath() {
      return this.$route.path
    }
  },
  watch: {
    '$route'() {
      this.setBreadcrumb()
    }
  },
  mounted() {
    this.setBreadcrumb()
  },
  methods: {
    setBreadcrumb() {
      const { matched } = this.$route
      this.breadcrumb = matched.filter(page => page.name).map(page => {
        return {
          name: page.meta.title,
          link: page.path
        }
      })
    }
  }
}
</script>
<style lang="less">
.el-breadcrumb__inner {
  color: #fff !important;

  a, &.is-link {
    color: #fff;
  }
}
</style>
