export const home = {
  system_name: "炽道国际教育",
  login: "登录",
  logout: "退出登录",

  banner: {
    title: "粤港澳大湾区高端国际教育开拓者与领导者",
    text: "炽道教育专注于帮助学生在国际学术课程中取得优异成绩，辅导包含AP、IBDP、IGCSE、A-level、HKDSE、Contest、IELTS、TOEFL、SAT、ACT、GRE等。",
  },
  NEWS: "最新资讯",
  plate_title: "课程",
  USA_Canada: "美加地区",
  ENG_HK: "英港地区",
  Fall_2024_Courses: "2024年秋季课程",

  about_us: {
    btn: "关于我们",
    title: "拥有大湾区独家顶尖师资团队",
    desc: "炽道教育自成立以来，以“炽盛初心、道远知骥”的办学理念，一步一个脚印发展成为粤港澳大湾区高端国际教育开拓者与领导者。炽道教育总部位于广州市天河区，联合创始人与首席导师均毕业于QS TOP30，美国U.S. News TOP20顶级学府，包括斯坦福大学、麻省理工学院、哥伦比亚大学、约翰霍普金斯大学、牛津大学、新加坡国立大学等，真正意义上实现了“精英成就精英”的教育模式，让学生在学习中不忘初心目标，跟随顶级名校导师走入顶级名校。",
  },
  course_desc: {
    title: "课程介绍",
    desc1: "炽道教育面向申请美国、英国、加拿大、新加坡、澳大利亚、香港等地区顶级学府的学生提供专业的课程规划，立足于学生学术及职业发展需求，提供必要的课程培训。",
    desc2: "炽道的课程主要分为三大类：标化测试、国际学科、竞赛挑战。其中，标化测试和国际学科课程致力于帮助学生满足申请院校的录取标准。竞赛挑战则是炽道专门为顶尖学生冲刺理想院校所搭建的桥梁，属于炽道独家特色课程，不仅提高学生的录取概率，同时为学生开启本科荣誉课程及高难度专业学习做出良好准备。",
  },
  feedback: {
    title: "学生反馈",
    feedback1: "“这次柯柯团队NEC全美经济学挑战亚洲总决赛能够拿到三项TOP10荣誉，多亏炽道Victor老师的指导！如果明年继续参赛AS，希望继续学习，整个过程孩子收获太多啦。”",
    feedback1f: "—— 柯柯妈妈（竞赛挑战）",
    feedback2: "“从入围中国总决赛到拿到CTB哈佛场资格，再到获得全球站三等奖，太感谢遇到Senqi老师，他的指导能力不容置疑！”",
    feedback2f: "—— Chris妈妈 （竞赛挑战）",
    feedback3: "“Joe非常幸运能够遇到炽道优秀的老师们！他们对于教学的热情，对学生负责的态度，以及专业的指导常常和孩子产生奇妙的化学反应，让孩子达到超越预期的结果。”",
    feedback3f: "—— Joe妈妈（国际学科）",
    feedback4: "“我们从普通初中进入国际高中，经历了非常忐忑的一年，孩子在高一最初来炽道的时候，语言和学科历史遗留行问题严重，但是炽道的Daniel规划老师提出了专业的选课和学习建议，班主任老师May全程关注孩子的学习，及时反馈问题。经过一年学习，孩子现在对于学习充满信息，期待未来的好消息！”",
    feedback4f: "—— ZX妈妈（国际学科、课程规划）",
    feedback5: "“Arthur进入伯克利学习后，经常回忆起当时和Jack老师学习时政写作的瞬间，他认为那些写作课程为他现在学习国际关系课程打下了坚实的基础。”",
    feedback5f: "—— Arthur妈妈（竞赛挑战）",
    feedback6: "“就算是周末没有课程的时候，Mary也很喜欢往炽道跑，她在那里认识了很多非常优秀的伙伴，他们有共同话题，会一起讨论选校以及课程问题，我很感谢炽道的老师和其他同学们陪伴Mary走过压力山大的申请阶段。”",
    feedback6f: "—— Mary妈妈（竞赛挑战、国际学科）",
  },
  footer: {
    ACADEMIC_OFFERINGS: "课程设置",
    Synchronous_Tutorial: "同步辅导课",
    CONSULTING: "咨询",
    Senior_Lecturer: "优秀导师",
    Teachers: "教师团队",
    Achievement: "学生成就",
    about_us: "关于我们",
  },
}

export const menu = {
  international_curricula: "国际课程",
  discipline_competition: "竞赛挑战",
  standardized_tests: "标化测试",
  resource: "资源中心",
  about_us: "关于我们",
  contact_us: "联系我们",

  Science_and_Technology: "理科与技术类",
  Arts_and_Languages: "文科与语言类",
  Social_Sciences_And_Economics: "社会科学与经济类",

  News: "新闻和博客",
  simulation_test: "线上模拟测试",

  Contact_Way: "联系方式",
  Online_Consult: "在线咨询",
  Join_Us: "加入我们",
}

export const news = {
  type1: "喜报",
  type2: "博客",
  type3: "活动",
  emptyStr: "暂无资讯"
}

export const about_us = {
  title: "关于我们",
  company_intro: {
    title: "公司介绍",
    desc: "炽道教育自成立以来，以“炽盛初心、道远知骥”的办学理念，一步一个脚印发展成为粤港澳大湾区高端国际教育开拓者与领导者。炽道教育总部位于广州市天河区，联合创始人与首席导师均毕业于QS前30,美国TOP20顶级学府，包括麻省理工学院、约翰霍普金斯大学哥伦比亚大学、斯坦福大学、牛津大学、新加坡国立大学等，真正意义上实现了“精英成就精英”的教育模式，让学生在学习中不忘初心目标，跟随顶级名校导师走入顶级名校。",
  },
  teacher: {
    title: "优秀导师",
    edu: '教育背景',
    wok: '工作经历',
    ach: '导师成就',
    te1: {
      name: "Senqi",
      edu1: "武汉大学本科，哲学系",
      edu2: "北京大学与哥伦比亚大学双硕士，经济与国际事务专业",
      wok: "于美国顶尖智库工作：布鲁金斯学会，战略与国际研究中心，德国马歇尔基金会；曾任凤凰卫视驻华盛顿记者站记者，现任头部外资风险咨询企业分析师",
      ach: "连续5年带领学生进入哈佛大学、耶鲁大学、哥伦比亚大学、布朗大学、西北大学等顶尖美国院校硕士项目；港新方面每年超过10名学生半奖学金录入金融经济、管理学、社会学、心理学、文学、哲学等专业硕士项目"
    },
    te2: {
      name: "Junyu",
      edu1: "英国伦敦大学，历史与考古专业硕士",
      edu2: "切尔西艺术学院，策展与收藏硕士",
      edu3: "美国罗德岛大学，经济学学士",
      wok: "于英国贝内特戴克斯全球头部古董拍卖公司，任亚洲部事务咨询专业：曾任北京再艺科技文物鉴赏与评估专家；向古玩艺术行业知名公共艺术媒体投稿20余篇，总浏览量超过10万次；在《中山日报》发表社论3篇学术研究。",
      ach: "指导John Lock神学类、政治类、历史类、经济类、Junior跨专题，学生获得一、二、三等奖、CTB国际三等奖，全国金奖（前五名），FBL记录为全球前15名。"
    },
    te3: {
      name: "Dr.Song",
      edu1: "留英5年，获得IC计算机专业博士学位",
      edu2: "华南农业大学计算机系教授",
      wok: "计算机系教授 持及参加国家级和省级科研项目10余项，发表论文50多篇，拥有发明专利和软件著作权。中国计算机学会高级会员，广东省计算机学会理事。获得微软MCP (Microsoft Certified Professional)和IBM的国际认证，2009年获得Sun Microsystems公司的Java培训讲师(SCJP Trainer)认证。2010年发起Java程序设计竞赛并任秘书长，举办7年，报名参赛的高校达到600多所。",
    },
    te4: {
      name: "Dr.Chen",
      edu1: "武汉大学，生物工程本科",
      edu2: "密歇根安娜堡，生物工程",
      edu3: "硕士 中国科学院大学，生物发育学博士",
      wok: "曾在密歇根大学Biophysics实验室课程担任研究生讲师;有A-LEVEL /AP/IG/IB 数学、物理及生物学科的丰富教学经验，为不同基础不同年龄的学生量身定制教学内容;擅长生物物理、数学科目竞赛指导与提分。",
      ach: "教授的每一个学生均达到或超过目标分数。学生覆盖省实，执信，碧桂园，广雅，广外，ULC等多所名校;A*率高达85%，5分率高达85%，学生连续三年获得HiMCM一等奖，获得Physicsbowl\BPhO金奖，PAT均分78。",
    },
  },
  team: {
    title: "教师团队",
    part1: {
      title: "我们的老师来自哪里？",
      desc: "炽道的学术团队由来自美国、英国、加拿大、香港、中国和世界其他地区的国际教师组成。这是一支高素质的教师团队，我们为团队成员的多样性感到无比自豪。我们的教师拥有广泛的专业领域，他们是各自所教授科目领域的专家，关注该科目知识性更新，研究考纲变化，并不断精进教学能力，针对于个体差异，选择不用的授课方式。",
    },
    part2: {
      title: "我们的教师来自一下部分大学：",
      us: {
        name: '美国',
        s1: "哈佛大学",
        s2: "麻省理工学院",
        s3: "斯坦福大学",
        s4: "哥伦比亚大学",
        s5: "耶鲁大学",
        s6: "杜克大学",
        s7: "约翰霍普金斯大学",
        s8: "加州理工学院",
        s9: "布朗大学",
        s10: "华盛顿大学",
        s11: "密歇根州立大学",
      },
      uk: {
        name: "英国",
        s1: "牛津大学",
        s2: "剑桥大学",
        s3: "帝国理工学院",
        s4: "伦敦政治经济学院",
        s5: "杜伦大学",
        s6: "华威大学",
      },
      hk: {
        name: "香港",
        s1: "香港大学",
        s2: "香港中文大学",
      },
      sg: {
        name: "新加坡",
        s1: "新加坡南洋理工大学",
        s2: "新加坡国立大学",
      },
    }
  },
  cases: {
    title: "成功案例",
    t1: {
      name: "夏*怡 同学",
      sc: "上海光华学院剑桥国际中心 高一在读",
      aw: "NEC竞赛 金奖",
    },
    t2: {
      name: "李*远 同学",
      sc: "广州碧桂园学校 高二",
      aw: "托福 117",
    },
    t3: {
      name: "尹*汉 同学",
      sc: "广州碧桂园国际学校 高二在读",
      aw: "NYT夏季读写比赛入围 Shortlisted",
    },
    t4: {
      name: "孙*轩 同学",
      sc: "深圳国际交流学院 高二",
      aw: "USABO生物竞赛 金奖",
    },
    t5: {
      name: "陆*怡 同学",
      sc: "光华剑桥国际学校 高二",
      aw: "BBO竞赛 金奖",
    }
  }
}

export const contact_us = {
  title: "联系我们",
  location: "广州天河区天河北路233号中信广场38层",
  btn: "在线咨询",
}

export const consult = {
  title: "在线咨询",
  contact: {
    title: "总校",
    loc: {
      title: "地址",
      add1: "广州市",
      add2: "天河区",
      add3: "天河北路233号",
      add4: "中信广场38层",
    },
    opn: {
      title: "开放时间",
      op1t: "周一到周五",
      op1v: "10am - 6pm",
      op2t: "周六周日",
      op2v: " 10am - 8pm",
    },
    con: {
      title: "联系方式",
      tel: "电话: 18028800860",
      eml: "邮箱: chido_public@outlook.com"
    }
  },
  form: {
    title: "咨询表单",
    desc: "非常感谢您对我们的课程感兴趣。我们将在一个工作日内回复您的咨询。",
    name: "姓名",
    ema: "邮箱",
    tel: "电话号码",
    cou: "咨询课程",
    com: "留言",
    namer: "请输入姓名",
    telr: "请输入电话号码",
    emailr: "请输入邮箱",
    btn: "提交",
    sus: "提交成功",
    subdou: "请勿频繁操作",
  }
}

// 加入我们
export const join_us = {
  title: "加入我们",
  btn: "具体介绍",
  p1: {
    title: "炽道职业发展道路",
    desc1: "我们致力于为员工提供清晰且富有成效的职业发展道路。无论您是刚踏入职场的新人，还是已经拥有丰富经验的专业人士，我们都为您提供量身定制的发展计划。通过持续的培训、辅导和职业指导，我们帮助员工实现个人和职业目标。",
    desc2: "加入炽道，开启您的职业新篇章，与我们一起创造美好的未来。",
  },
  p2: {
    title: "您是炽道的合适人选吗？",
    desc: "我们正在寻找充满热情、富有创造力且具备卓越教学能力的教师加入我们的团队。炽道重视教师的专业成长，为每位教师提供丰富的培训资源和发展机会。如果您对教育充满热情，并希望在一个充满活力和合作精神的团队中工作，请加入我们，共同塑造下一代的未来。我们目前正在寻找以下职位候选人：",
    s1: {
      title: "以下为科目的教师：",
      j1: "· 数学",
      j2: "· 经济/商务",
      j3: "· 生物",
      j4: "· 地理",
      j5: "· 历史",
      j6: "· 化学",
      j7: "· 生物学",
    },
    s2: {
      title: "其他职位：",
      j1: "· 网络新媒体专员",
      j2: "· 国际学科线下渠道经理",
      j3: "· 国际课程顾问（I级别）",
    }
  }
}

// 职位详情
export const job_o = {
  title: "职位详细介绍",
  btn: "继续阅读",
  j1: {
    title: "国际课程顾问",
    desc: "工作亮点在炽道，教育是第一位的。我们相信学业成功和高质量的学业规划密不可分。多年来，我们的教育顾问帮助无数学生进入世界各地的精英学校。如果您有兴趣提高技能并超越专业领域",
    tag: "职位空缺，非教学",
  },
  j2: {
    title: "市场经理",
    desc: "在炽道炽道，教育是第一位的。我们相信学术成功和高质量的学术规划是相辅相成的。多年来，炽道已帮助无数学生达成学术目标，获得世界各地精英学校的录取。如果您有兴趣在多元文化和团队导向的环境中提高您的技能并超越您的专业领域，我们欢迎您加入我们！",
    tag: "职位空缺，非教学",
  },
  j3: {
    title: "政治老师",
    desc: "炽道国际教育是粤港澳大湾区具备领导力的教育服务机构。我们提供涵概IB，AP，I/GCSE，GCE - A LEVEL，SAT，托福，雅思，统一入学考试及香港本地制等众多考试体系的不同课程。",
    tag: "职位空缺，教学",
  },
  j4: {
    title: "IB/AP学科老师",
    desc: "工作重点在炽道，教育是第一位的。我们相信学业成功和优质教学密不可分。在炽道担任学科是一种充满挑战但又收获颇丰的经历，没有什么比对学生的生活产生积极影响更令人欣慰的了。我们寻找一位具有以下特质的学科老师。",
    tag: "职位空缺，教学",
  },
  j5: {
    title: "雅思/托福老师",
    desc: "工作重点在炽道，教育是第一位的。我们相信学业成功和优质教学密不可分。在炽道担任学科是一种充满挑战但又收获颇丰的经历，没有什么比对学生的生活产生积极影响更令人欣慰的了。我们寻找一位具有以下特质的学科老师。",
    tag: "职位空缺，教学",
  },
}

export const job_d = {
  desc1: "有意者请在我们的",
  desc2: "注意：入围申请人将由人力资源部工作人员邀请参加面试。",
  btn: "招聘页面提交申请及相关证明文件。",
  j1: {
    title: "国际课程顾问",
    p1: {
      title: "择才标准：",
      desc1: "1. 富有团队精神，应变能力强，能够营造良好的团队协作氛围；",
      desc2: "2. 能够通过定量定性的分析和思考，来拆解及解决问题；",
      desc3: "3. 在团队项目中，能够独立规划并完成自己应负责的工作；",
      desc4: "4. 具备良好的中英文交流与读写能力为佳（此项仅限于应届毕业生）；",
    },
    p2: {
      title: "岗位职责：",
      desc1: "1. 承接公司客户资源，积极回访客户，通过微信、面谈与学员沟通交流，挖掘客户需求，提供咨询服务；",
      desc2: "2. 能够快速分析学员的需求，并为学员设计合适的方案，根据学员的学习状况做出分析，制定学习计划与方案，并引导报名，促进签约及后续跟进；",
      desc3: "3. 主动维护潜在客户关系，扩大公司品牌及行业口碑；",
      desc4: "4. 按质按量地完成校区下达的销售任务，完成上级交付的工作；",
    },
    p3: {
      title: "能力提升：",
      desc: "炽道是一家愿意为你的职业发展“投资”的雇主。作为一名国际课程顾问，可以在下述几方面接受培训和指导，从而提升综合实力：",
      desc1: "1. 梳理问题，并采取适当的行动加以解决；",
      desc2: "2. 利用定量及定性的方法将复杂的信息抽丝剥茧，清晰罗列要点，并给出建议；",
      desc3: "3. 与多样化的团队高效合作，找到最佳解决方案，并将大家动员起来；",
      desc4: "4. 基于相互信任，建立优质的客户关系，更好地为客户提供服务；",
      desc5: "5. 以成熟且恰当的方式和同事、客户、管理层进行高效沟通；",
      desc6: "6. 塑造自己的领导力风格，充分释放个人的激情、优势和价值",
    }
  },
  j2: {
    title: "市场经理",
    p1: {
      title: "择才标准：",
      desc1: "1. 负责开发、维护区域内的重点客户，开展项目合作并保障良好关系；",
      desc2: "2. 协助搭建普高、国际高中、本科院校的关系网，通过有效维护，开展相关合作并形成稳定的有效资源供给；",
      desc3: "3. 完成目标市场内的市场活动的策划、宣传和执行；",
      desc4: "4. 关注目标市场和客户，进行调研、整理和分析工作，掌握市场最新动向并参与公司市场决策；",
      desc5: "5. 配合公司市场占率方向，正确传递公司价值，成为公司与客户、合作方向的桥梁。",
    },
    p2: {
      title: "岗位职责：",
      desc1: "1. 具备市场营销、传播或公共关系或相关学科大专或以上学历",
      desc2: "2. 相关市场行业经历者优先；应届毕业生也将被考虑",
      desc3: "3. 精通Office办公软件",
      desc4: "4. 精通Adobe IIIustrator/ Photoshop/ InDesign/ 视频编辑应用程序者优先",
      desc5: "5. 强烈的责任感、分析力、创造力和对营销的热情",
      desc6: "6. 良好的团队合作精神，具有良好的中文书面和口语沟通能力",
    },
    p3: {
      title: "岗位福利：",
      desc1: "1. 在充满活力和多元文化的工作环境中每周工作5天",
      desc2: "2. 位于广州市中心的黄金工作地点",
      desc3: "3. 全面的培训和专业发展计划",
      desc4: "4. 市场领先组织的职业发展机会",
      desc5: "5. 节假日保健礼品",
      desc6: "6. 有竞争力的薪酬待遇",
    }
  },
  j3: {
    title: "政治老师",
    p1: {
      title: "岗位职责：",
      desc1: "1. 教授国际公民与社会课程",
      desc2: "2. 根据教材设计新课程，制作教学大纲",
      desc3: "3. 撰写学生进度报告",
    },
    p2: {
      title: "任职要求：",
      desc1: "1. 持有中国语言文学或政治相关学位",
      desc2: "2. 有意与教育机构作长远合作",
      desc3: "3. DSE公社或高中政治课程补习经验者优先考虑",
      desc4: "4. 对a-level/IG等体系有一定的了解，或对教学感兴趣；",
    },
  },
  j4: {
    title: "IB/AP学科老师",
    p1: {
      title: "岗位职责：",
      desc1: "1. 教授文理科AP、IB 数理化、生物、历史、地理等国际学校各年级、文理科科目，能教授其中一内或以上的科目均可",
      desc2: "2. 收集教学前端最新数据，为教学的新方法提供建议；定期参与公司组织的教学研究讨论会，提升教学效果及质量；",
      desc3: "3. 及时与班主任、助教反馈学生的教学进度，方便助教做好后期跟进与维护；",
      desc4: "4. 完成教学部内负责人安排的其他任务。",
    },
    p2: {
      title: "任职要求：",
      desc1: "1. 本科及以上学历，所教授科目为专业所学，雅思7.0+，有海外留学经验优先；",
      desc2: "2. 对AP/IB等体系有一定的了解，或对教学感兴趣；",
      desc3: "3. 有国际学校的任教经历优先考虑，持有教师资格证（PGCE或相关证书）优先考虑；",
      desc4: "4. 热爱教育事业，语言表达能力强，工作积极主动，对学生耐心负责，有良好的教学职业道德及较强的工作责任心",
    },
  },
  j5: {
    title: "雅思/托福老师",
    p1: {
      title: "任职要求：",
      desc1: "1. 本科及以上学历，所教授科目为专业所学，雅思7.0+，有海外留学经验优先；",
      desc2: "2. 对雅思/托福等体系有一定的了解，或对教学感兴趣；",
      desc3: "3. 有国际学校的任教经历优先考虑，持有教师资格证（PGCE或相关证书）优先考虑；",
      desc4: "4. 热爱教育事业，语言表达能力强，工作积极主动，对学生耐心负责，有良好的教学职业道德及较强的工作责任心",
    },
  }
}

// 求职表单
export const job_f = {
  title: "求职申请表单",
  btn_p: "上一步",
  btn_n: "下一步",
  btn_s: "提交",
  form_r: "请完善",
  empty_tip: "如果不适用，请填写“NA”",
  Submit: {
    success: "提交成功",
    confirm_t: "确认提交",
    confirm_d: "我们将尽快阅读您的申请",
    confirm_y: "确认",
    confirm_n: "取消",
  },
  A: {
    title: "个人资料",
    name: "姓名:",
    gender: "性别:",
    gender1: "男",
    gender2: "女",
    birthday: "出生日期:",
    address: "地址:",
    phone: "电话号码:",
    email: "邮箱:",
    target_job: "申请职位:",
    photo: "近期照片:",
  },
  B: {
    title: "教育与培训",
    add_c: "创建新条目",
    add_c_t_t: "确认操作",
    add_c_t_d: "确定删除此条目?",
    add_c_t: "确定创建新条目?",
    add_c_y: "确定",
    add_c_n: "取消",
    img_title: "教育证书和成绩单副本",
    c: {
      title: "其他教育:",
      school: "学校名称:",
      address: "地址:",
      start_date: "开始日期:",
      end_date: "结束日期:",
      add_class: "添加学科记录",
      class_title: "所修科目及成绩:",
      class_sub: "科目",
      class_val: "成绩",
    },
    c1: {
      title: "中学教育（高中）必修领域:",
      class_title: "所修科目及成绩:",
      class_sub: "科目",
      class_val: "绩点/成绩（得分/总分）",
    },
    c2: {
      title: "高等教育（学士/文凭，其次为最高学位）:",
      class_title: "学位/文凭和专业:",
      class_sub: "科目",
      class_val: "绩点/成绩（得分/总分）/荣誉等级",
    }
  },
  C: {
    title: "工作经历",
    title2: "工作经历（按时间倒序排列）",
    add_c: "创建新条目",
    company: "任职公司:",
    start_date: "开始日期:",
    end_date: "结束日期:",
    job: "职位:",
    pay: "薪水:",
    leave_reason: "离职原因:",
  },
  D: {
    title: "其他信息",
    arrival_date: "最快到岗日期:",
    target_pay: "期望月薪:",
  },
  E: {
    title: "声明",
    r: "请同意声明",
    desc1: "同意及声明",
    desc2: "本人特此确认并同意如下:",
    desc3: "我保证，据我所知，此申请中提供的所有信息均真实、完整，并且我没有隐瞒任何信息，如果泄露，可能会改变此申请的完整性。",
  },
}

// 国际课程
export const i_c = {
  title: "国际课程",
  info: {
    desc1: "炽道教育的国际课程为学生进入高等教育做好准备。这些课程提供进阶学术知识，培养批判性思维，并帮助学生发展重要的技能，如时间管理和独立研究的能力。",
    desc2: "AP课程取得优异成绩的学生可换取大学学分，或在大一进入荣誉课程或高阶课程，从而减少未来本科阶段的学习周期和费用。A-Level为英国体系下的学生三年本科学习做好铺垫，训练了学生处理高难度课程的能力，扩展了学生的知识广度。HKDSE课程则为港籍申请香港院校的学生提供了多通道、更有优势的入学方式。",
    desc3: "在炽道，授课的老师具备全球视野，不仅在具体科目上帮助学生从学术角度脱颖而出，还在大学录取和未来发展中为学生提供宝贵建议。",
  },
  ap_ib: {
    desc1: "欢迎来了解炽道的Advanced Placement (AP) 和 International Baccalaureate (IB) 课程项目！",
    desc2: "我们致力于为学生提供全面而高质量的国际课程培训，帮助他们在学术上取得卓越成就。",
    desc3: "AP课程主要在美国使用，允许学生在多个科目中学习大学水平的课程并参加考试。高分成绩可以换取大学学分或获得高阶课程安排。IB课程是国际认可的课程，强调批判性思维、跨文化理解和六个学科组的严格评估，最终获得IB文凭，受到全球大学的高度认可。这两个项目都旨在通过培养学术卓越和全球意识，为学生的高等教育做好准备。",
    btn_t: "课程详情",
    ap: {
      arc: "AP优秀学员案例",
      p1: {
        desc1: "炽道国际教育凭借其卓越的教学实力与优质的师资力量，迅速在广州及大湾区建立了领先的AP课程培训体系。AP项目自2022年创立以来，便专注于美国大学先修课程的深入研究与实践，为学生提供高质量的学术支持与辅导服务。2023年，炽道国际教育AP项目的学生在顶尖美国大学的录取率达到显著提升，成为区域内备受推崇的AP培训品牌。",
        desc2: "根据学生的学习需求与个人特点，炽道国际教育长期开设精品班、全日制班、高端一对一等多种班型，致力于帮助学生在AP考试中取得优异成绩，顺利进入理想的美国及全球顶尖学府。",
      },
      p2: {
        title: "什么是AP课程",
        desc1: "AP即美国大学预修课程，是由美国大学理事会（College Board）主持，在高中阶段修习大学难度的课程并参加同等水平考试。",
        desc2: "共设38个学科，主要分为6大类，分别为艺术类、英语类、历史与社会科学类、数学与计算机科学类、自然科学类、以及世界语言与文学类。",
        desc3: "中国学生一般会选择3-6门课程学习，每门AP成绩都按照选择题和自由答卷的回答结合起来做出判断。",
      },
      p3: {
        title: "AP选课数量及冲刺院校",
        desc: "官方没有明确给出选课数量建议，根据历年美国大学录取情况来看：",
        desc1: "· Top 15（藤校）至少需要6-10门",
        desc2: "· Top 30至少需要5-7门",
        desc3: "· Top 50至少需要3-4门",
      },
      p4: {
        title: "AP评分规则",
        desc1: "采取5分制：",
        desc2: "5=优秀，4=很好，3=合格，2=勉强合格，1=不合格",
        desc3: "排名靠前的大学要求4分或5分才能换取大学学分，一般3分以上被多数学校接受",
      },
      p5: {
        title: "学习AP课程的好处：",
        l1: "增加GPA成绩",
        l2: "知名院校录取依据",
        l3: "备考周期较短",
        l4: "换取美国大学学分，省学费",
        v1: "GPA是美国知名大学录取时的重要考虑因素，平均每门AP课程成绩课增加GPA分值0.1分",
        v2: "AP课程是美国知名院校确信学员敢于挑战学术难度，明确学术发展方向的重要指标",
        v3: "一般为理论测试，没有实操部分，大多可以在一年内学完并参加考试",
        v4: "全球数千所大学大学级学院接受AP考试并授予大学学分，一门AP课程可以换取3-5个大学，学分，美国私立院校本科每学分价值约7000元",
      },
      p6: {
        title: "课程亮点",
        desc1: "科学规划入学",
        desc2: "全程顶级师资",
        desc3: "独家服务体系",
      },
    },
    ib: {
      p1: {
        desc1: "炽道国际教育作为大湾区高端国际全品类学科培训品牌，不仅在A-level和DSE项目上取得了卓越成就，还致力于IB课程的深入研究与教学实践。",
        desc2: "IB项目自2022年创立以来，迅速组建并发展了一支强大的专业教学团队，专注于帮助学生取得顶尖成绩。炽道国际教育根据学生的不同特点和需求，长期开设IB精品提升班、全日制脱产班和高端一对一辅导课程。凭借其卓越的教学质量和强大师资力量，炽道国际教育已成为广州及大湾区内IB课程培训的领军机构。",
      },
      p2: {
        title: "什么是IB课程",
        desc1: "国际文凭课程（International Baccalaureate，简称IB）是全球公认的严谨而全面的教育项目，旨在培养学生的批判性思维、跨文化理解能力以及全球视野。IB课程适用于3至19岁的学生，涵盖小学项目（PYP）、中学项目（MYP）、大学预科项目（DP）和职业相关项目（CP）。其中，大学预科项目（DP）尤其受到全球顶尖大学的青睐。",
        desc2: "IB课程独特之处在于它不仅注重学术成绩，还强调学生的全人发展。通过多学科整合的教学方法，IB学生能够在六大学科组别中深度探索，并参与理论知识（TOK）、拓展论文（EE）和创新、行动与服务（CAS）等核心项目。这些学习体验帮助学生培养批判性思维能力、研究能力和责任感，使他们为未来的大学学习和职业生涯做好充分准备。",
      },
      p3: {
        title: "课程亮点：",
        desc1: "全球认可的学术严谨性",
        desc2: "全人发展的教育理念",
        desc3: "跨文化理解与全球视野",
      },
    },
  },
  ig_al: {
    desc1: "欢迎来了解炽道的IGCSE（International General Certificate of Secondary Education）和A-Level（Advanced Level）课程项目！",
    desc2: "我们致力于为学生提供全面而高质量的国际课程培训，帮助他们在学术上取得卓越成就，开设针对爱德思考试局、CIE考试局、以及AQA考试局培训课程。",
    desc3: "IGCSE通常由14至16岁的学生参加，涵盖广泛的科目，为进一步学习提供坚实的基础。A-Level更为高级，适合16至18岁的学生，专注于特定学科的深入学习。A-Level资格受到全球大学的高度重视，通常是本科项目入学的要求。IGCSE和A-Level共同为学生的高等教育和职业生涯做好准备，培养批判性思维和学科专业知识。",
    btn_t: "课程详情",
    ig: {
      p1: {
        desc1: "炽道国际教育作为大湾区高端国际全品类学科培训品牌，凭借其卓越的学科教学能力和强大师资，迅速组建并发展成为广州地区唯一专业A-level教研与教学团队。A-level项目最早创立于2022年，并在2023年成为广州及大湾区录取率最高的专业培训机构。所有学员在2023年录取中，英国G5大学录取率高达78%。",
        desc2: "炽道国际教育致力于A-level考试的深入研究与实践，根据学生的不同特点和需求，长期开设精品提升班、全日制脱产班、高端一对一等班型，并创立了专属的A-level品牌——“炽道A-level”。",
      },
      p2: {
        title: "IGCSE是什么课程",
        desc1: "IGCSE课程是International General Certificate of Secondary Education，又被称为国际普通初级中学毕业文凭，是国外14岁至16岁全世界考试最多人数的体系之一。是剑桥全球测试的一部分。其证书，只要是说英文的国家地区均会得到承认。",
        desc2: "IGCSE标准学制为两年，分别对应G1，G2年级。IGCSE课程有70多门学科，其中有30多门语言类学科，必修科目为：英语，数学，科学，其他可按需进行选修。（注：科目选修的选择为高中最后两年学习A-Level或IB做铺垫和打基础）。",
      },
      p3: {
        title: "为什么要学习IGCSE课程？IGCSE有多重要？",
        l1: "衔接高难度的A Level课程：",
        v1: "A Level课程与IGCSE相比，其重要性自然要大的多，毕竟这可是英国的“高考”，大学申请时主要看的就是A Level的成绩。但是，A Level就是IG的“延续”。IG学到的知识点已经足够宽泛，甚至已经覆盖完了AS的知识。在AS阶段，就是知识点更加深入的过程。",
        l2: "作为大学申请的参考：",
        v2: "如今已经有越来越多的英国知名大学将IG成绩也列为录取的重要参考指标，甚至成为与预估成绩和AL大考成绩占据同等比重的部分。",
        l3: "为大学的申请早做准备：",
        v3: "除了必修的数学、英语和科学，学生还可以根据自己的兴趣爱好选择其他科目，从社会科学(通常是会计、商业研究、经济学、社会学)到艺术与技术(通常是计算机研究、信息与通信技术(ICT、艺术与设计))。学生在每个科目都将获得一个IG证书。",
      },
      p4: {
        title: "课程亮点",
        desc1: "经验丰富的教师授课",
        desc2: "个性化辅导与支持",
        desc3: "提供丰富的学科与研究能力",
      }
    },
    al: {
      p1: {
        desc1: "A-level项目最早创立于2022年，并在2023年成为广州及大湾区录取率最高的专业培训机构。所有学员在2023年录取中，英国G5大学录取率高达78%。",
        desc2: "炽道国际教育致力于A-level考试的深入研究与实践，根据学生的不同特点和需求，长期开设精品提升班、全日制脱产班、高端一对一等班型，并创立了专属的A-level品牌一“炽道A-level”。",
      },
      p2: {
        title: "A-Level是什么课程",
        desc: "AL涵盖英国高中至大一课程内容，课程横 跨专题广，AS阶段难度适中，A2阶段具备 挑战性。学生一般需要用1.5-3年的时间完成AL全部考试。申请中，学生可以在完成AS 部分（或部分科目卷子）后，提交实考和预估分申请目标院校。",
      },
      p3: {
        title: "两大考试局",
        l1: "①CIE（剑桥）：",
        l2: "②Edexcel（爱德思）：",
        v1: "单次报考续完成AS或AL部分多卷，理科难度偏大，文科适中",
        v2: "难度中等，各科目可分卷考试，培生旗下AL考试产品，录取参考最高并分",
      },
      p4: {
        title: "前置衔接模式",
        l1: "1.两年的IG衔接，",
        l2: "2.Pre模式，",
        v1: "在国际高中读4年，G1G2+ASA2。 ",
        v2: "就是把IG的两年衔接并 为一年Pre，读完Pre升入A-Level，在国际学校就读3年，Pre+ASA2",
      },
      p5: {
        title: "评分标准",
        desc1: "若单科满分为600分：",
        desc2: "A*为最优，单卷最高评分为A,E为通过，U为不及格",
      },
      p6: {
        title: "为什么要学习IGCSE课程，IGCSE有多重要",
        l1: "课程更具结构性和灵活性：",
        l2: "非常多科目可选：",
        l3: "同样是考试为主：",
        l4: "可以获得重要能力：",
        v1: "A-level学制两年，第一年叫AS，第二年叫A2，一般建议AS学4门课，A2学3门课。与其他课程体系不同，A-level不需要学习那么多课程来分散精力，对偏科型的同学来说是福音。",
        v2: "A-level有非常多的科目可选，一般选课之前学校会根据你的升学方向和未来职业目标选课，让你获得更大的竞争优势。",
        v3: "A-level课程考核贯穿两年学习的始终，但大多数科目都以考试为主，公平客观，对擅长考试的学生来说，比较容易获得好的成绩。",
        v4: "A-level是英国大学的准备课程，在A-level阶段获得的学科知识、学习方式和思考方式、时间管理能力等，对紧张的大学学习来说是一种有效准备。",
      },
      p7: {
        title: "课程亮点",
        desc1: "优质教研团队",
        desc2: "满足不同学员需求",
        desc3: "服务内容多样化",
      },
    },
  },
  hk: {
    p1: {
      desc1: "炽道DSE项目最最创立于2022年，同时在2023年成为广州及大湾区录取率最高的专业培训机构，培训所有学员在2023年录取中，港八大录取率高达78%，并创立专属DSE品牌——“炽道DSE”",
      desc2: "炽道国际教育作为大湾区高端国际全品类学科培训品牌，凭借独有的学科教学能力和强大师资。迅速组建并发展为广州地区唯一专业DSE教研与教学团队，致力于DSE考试的研究与实践。根据学生不同特点和需求，长期开设精品提升班，全日制脱产班，高端一对一等班型。",
    },
    p2: {
      title: "什么是DSE考试",
      desc1: "DSE考试分为三大类:",
      l1: "甲类A：",
      v1: "4科高中科目（4个核心科目包括中国语文、英国语文、数学、公民与社会发展及20个选修科目）",
      l2: "乙类B：",
      v2: "应用学习科目。考生的成绩须经考评局审定，成绩分别以两个等级汇报，即“达标”和“达标并表现优异”。",
      l3: "丙类C：",
      v3: "其他语言科目：法语、德语、日语、西班牙语等。",
    },
    p3: {
      title: "DSE的优势",
      l1: "考试时间灵活：",
      v1: "香港文凭的考试时间安排在每年3-5月，学生可在不影响高考的情况下参加HKDSE考试。",
      l2: "300所认可：",
      v2: "全球近300所高等院校欢迎持有HKDSE成绩的同学申请入学。",
      l3: "2年有效：",
      v3: "HKDSE成绩可以保留两年，若第一年成绩不理想，可以在第二年重考个别或全部科目，取两年最好的单科成绩合并作为最终申请成绩。",
      l4: "4 + 2自由选：",
      v4: "选科自由。除了4科必修科目外，学生可根据自己的能力、兴趣选择2-3门选修科目。",
    },
    p4: {
      title: "课程亮点",
      desc1: "顶级专业师资紧跟考纲全科教研",
      desc2: "独家教学体系自研教材专属题库",
      desc3: "服务管理到位学管团队经验丰富",
    }
  },
}
// 竞赛学科
export const d_c = {
  title: "竞赛挑战",
  info: {
    title1: "什么是国际竞赛（International Contests）？",
    desc1: "这是一场全球范围内举办的活动，区别于标化考试（Standard Test）中人与出题组之间的PK，竞赛是人与人的PK，学生可凭借个人意愿决定参加与否。国际竞赛中，学生进行超越自我的挑战，没有恒定解答方式，竞赛者并不会因表现受到高低排名，主办方更倾向于找出所有参赛者中的前百分之多少（例如：全球TOP 5%）",
    desc2: "我们的竞赛学科项目旨在帮助学生在各类国内外学术竞赛中脱颖而出。我们提供专业的指导和系统的培训，涵盖数学、物理、化学、生物、计算机科学等各大竞赛学科。通过我们精心设计的课程，学生将全面提升学科知识和竞赛技能，培养逻辑思维能力和创新能力。",
    desc3: "我们拥有全球独家超强精英指导团队，他们不仅熟悉各类竞赛的题型和考察重点，还能根据每位学生的特点量身定制学习计划。我们的课程包括基础知识巩固、竞赛题型讲解、模拟考试和答题技巧指导等，帮助学生在激烈的竞赛中取得优异成绩。加入我们的竞赛学科项目，让我们一起开启通往学术巅峰的旅程，为未来的学习和职业生涯打下坚实的基础。",
    desc4: "炽道竞赛项目希望赋能学生探索未知学术领域，引导他们解决世界上关键和复杂问题的兴趣。",
    title2: "炽道竞赛优势Pros",
    ds1: "√  行业最高获奖率，98%超高获奖率",
    ds2: "√  全球独家超强精英指导团队",
    ds3: "√  Top 10名校毕业专属竞赛顾问实时提供精准信息并答疑",
    ds4: "√  前沿竞赛培训专家提供最新、最高效的个性化课程",
    ds5: "√  联合80+留学机构协助组队、建立可靠安全的竞赛资源共享平台",
  },
  c1: {
    title: "理科与技术类",
  },
  c2: {
    title: "文科与语言类",
  },
  c3: {
    title: "社会科学与经济类",
  },
  sat: {
    title: "理科与技术类",
    c1: {
      title: "数学与建模",
    },
    c2: {
      title: "计算机科学",
    },
    c3: {
      title: "物化生",
    },
  },
  aal: {
    c1: {
      title: "写作与演讲"
    }
  },
  sse: {
    c1: {
      title: "经济与金融"
    }
  }
}

// 美加地区
export const u_c = {
  title: "美加地区",
  info: {
    desc1: "炽道的美国和加拿大体系课程旨在帮助学生全面适应和掌握北美教育体系的要求和标准。我们提供从初中到高中的全方位课程，包括英语、数学、科学、社会学科以及选修课程，确保学生在各个方面都能获得全面发展和提升。",
    desc2: "炽道的课程设计遵循美国和加拿大的教育大纲，重点培养学生的批判性思维、创造力和解决问题的能力。通过小班教学和个性化辅导，我们的经验丰富的教师团队能够针对每位学生的学习特点和需求，提供量身定制的学习计划和支持，帮助他们在学术上取得优异成绩。",
    desc3: "除了AP（Advanced Placement）和IB （International Baccalaureate）以及SAT和ACT课程，炽道教育于2023年获Toronto International Academy (TIA) 授权，提供OSSD（Ontario Secondary School Diploma）学历课程。通过科学的规划、专业导师授课，以及全程陪伴服务，炽道希望通过课程增强学生的学术能力，为学生申请北美名校打下坚实的基础，养成良好学习习惯。",
    desc4: "加入我们的美国加拿大体系课程，为您的孩子打开通往北美顶尖学府的大门，全面提升学术竞争力和综合素质。",
  }
}
// 英港地区
export const e_h = {
  title: "英港地区",
  info: {
    desc1: "我们的英国和香港体系课程致力于帮助学生适应并掌握英式教育的要求和标准，为他们在未来的学术道路上打下坚实的基础。我们提供从初中到高中的广泛课程，包括英语、数学、科学、人文学科以及选修课程，确保学生在各个学科领域都能获得全面发展。",
    desc2: "我们的课程设计严格遵循英国和香港的教育大纲，注重培养学生的批判性思维、创造力和独立学习能力。通过一对一教学和个性化辅导，我们的专业教师团队能够根据每位学生的学习需求和特点，制定量身定制的学习计划，帮助他们在学术上取得优异成绩。",
    desc3: "除了基础课程，我们还提供IGCSE（国际普通中等教育证书）和A-Level课程，这些课程不仅可以提升学生的学术能力，还能为他们申请世界顶尖大学提供有力支持。同步，我们开设香港DSE（文凭试）课程，为港籍学生多通道升学保驾护航。",
    desc4: "此外，我们还提供英国和香港标准化考试的培训，如IELTS、UKCAT等，帮助学生在这些关键考试中取得优异成绩。我们的课程不仅关注学术成绩，还重视学生的综合素质培养，确保他们在激烈的竞争中脱颖而出。",
    // desc5: "加入我们的英国香港体系课程，为您的孩子打开通往世界名校的大门，全面提升学术竞争力和综合素质。",
  }
}
// 标化测试
export const s_t = {
  title: "标化测试",
  info: {
    desc1: "我们的标准化测试课程专为准备参加雅思（IELTS）、托福（TOEFL）、SAT、ACT、GRE、GMAT等考试的学生量身定制，旨在帮助学生在这些全球认可的考试中取得优异成绩，为进入理想大学铺平道路。",
    title1: "雅思（IELTS）和托福（TOEFL）课程",
    desc2: "雅思和托福是申请英语国家大学和学院时最常见的英语语言能力测试。我们的雅思和托福课程由7年以上经验教师团队授课，从听力、阅读、写作和口语四大部分切入，通过系统的训练和模拟考试，帮助学生提高综合语言能力，熟悉考试形式，掌握答题技巧，从而在考试中取得理想成绩。",
    title2: "SAT和ACT课程",
    desc3: "SAT和ACT是美国大学入学的重要标准化测试。我们的SAT和ACT课程覆盖数学、阅读、写作和科学推理等项目。课程注重解题策略和时间管理，通过大量的练习和模拟考试，帮助学生提高应试能力，增强自信心，最终在考试中脱颖而出。",
    title3: "我们的标准化测试课程特色：",
    ds1: "一对一教学：确保每位学生都能获得个性化关注和指导。",
    ds2: "全真模拟：定期举行全真模拟考试，让学生熟悉考试环境和节奏。",
    ds3: "定制辅导：根据每位学生的弱项和需求，制定个性化的学习计划和辅导方案。",
    ds4: "资源丰富：提供大量的练习题库、备考资料和学习资源，帮助学生全面备考。",
  },
  // IELTS_TOEFL
  ie_to: {
    p1: {
      desc: "我们的雅思和托福语言培训课程旨在帮助学生全面提升英语听说读写能力，以应对国际标准化考试的挑战。通过系统化的教学和大量的练习，我们的学生能够熟练掌握考试技巧，提高语言水平，从而在考试中取得优异成绩，为进入理想的海外院校打下坚实基础。",
    },
    p2: {
      title: "我们的课程主要的三大优势是：",
      desc1: "1. 行业真正超级名师，教龄10年以上，综合出分率98%！！！",
      desc2: "2. 从规划到出分实行“双师导师制”，督导与名师全程监控！！",
      desc3: "3. 专门定制小班2:1精讲精练课程，效率高，出分快，费用低！！",
    },
    p3: {
      l1: "雅思课程：",
      v1: "全面提升听、说、读、写四项技能，针对学术和移民需求设置课程。",
      l2: "托福课程：",
      v2: "专注于提高学生的学术英语能力，特别适合准备赴美留学的学生。",
      l3: "课程内容：",
      v3: "包括听力练习、口语互动、阅读理解、写作技巧等。",
      l4: "教学方法：",
      v4: "小班授课、一对一辅导、模拟测试等。",
    }
  },
  // SAT_ACT
  sat_act: {
    p1: {
      desc: "SAT和ACT是美国大学入学的重要考试，各自有不同的结构和侧重点。我们的备考课程旨在帮助学生全面理解并掌握这些考试所需的知识和技巧，提升整体考试成绩。并且，SAT和ACT成绩是大学录取的重要参考标准之一。良好的考试成绩可以帮助学生进入理想的大学并获得奖学金。",
    },
    p2: {
      title: "课程结构",
      title1: "我们的课程分为不同模块，包括：",
      l1: "√ 数学：",
      v1: "涵盖代数、几何、数据分析等。",
      l2: "√ 阅读：",
      v2: "提高学生的阅读理解能力，分析复杂文本。",
      l3: "√ 写作：",
      v3: "教授学生如何写出清晰、有力的文章。",
      l4: "√ 科学推理（ACT特有）：",
      v4: "帮助学生理解和分析科学信息。",
    },
    p3: {
      title: "课程进度表",
      desc1: "√ 每个模块的详细教学计划和时间安排。",
      desc2: "√ 模拟考试和复习阶段的安排，确保学生在考试前能够全面复习和自我测试。",
    },
    p4: {
      title: "课程亮点",
      desc1: "吸收并运用解释和消除等有效的应试技巧",
      desc21: "熟悉独特的考试形式",
      desc22: "选择适合自己的考试（ACT / SAT）",
      // desc2: "熟悉独特的考试形式，选择适合自己的考试（ACT / SAT）",
      desc3: "精准地将学科知识运用到考试题目中",
    }
  },
  // GRE/GMAT
  gr_gm: {
    desc1: "GRE（Graduate Record Examinations）和GMAT（Graduate Management Admission Test）是两个主要用于研究生入学的标准化测试。GRE适用于广泛的研究生课程申请，而GMAT通常是申请商学院和MBA项目的首选考试。两者都包括数学、语文和分析写作部分，但GMAT还包括综合推理部分。",
    desc2: "√ 适合计划攻读MBA、管理、经济、工程、心理学、社会科学等领域研究生课程的学生。",
    gre: {
      p1: {
        title: "概述",
        desc: "GRE（Graduate Record Examination）是由教育考试服务中心（ETS）举办的一项国际性研究生入学考试。GRE考试旨在衡量考生的语文、数学和分析性写作技能，是世界各地大学评估申请者是否具备研究生阶段学习能力的重要标准之一。",
      },
      p2: {
        title: "考试内容",
        desc: "GRE考试分为普通考试（General Test）和专项考试（Subject Tests）。",
        s1: {
          title: "普通考试主要包括三部分：",
          l1: "1.语文推理（Verbal Reasoning）：",
          v1: "评估考生的词汇和理解能力。",
          l2: "2.数学推理（Quantitative Reasoning）：",
          v2: "评估考生的基本数学技能及数据分析能力。",
          l3: "3.分析性写作（Analytical Writing）：",
          v3: "评估考生的批判性思维和分析写作能力。",
        },
        s2: "专项考试包括生物学、化学、文学、数学、物理学、心理学等科目，主要用于评估考生在特定领域的知识和技能。"
      },
      p3: {
        title: "考试时间及费用",
        l1: "·考试时间：",
        l2: "·考试费用：",
        v1: "全年多次举行，考生可以根据自己的时间安排选择合适的考试日期。",
        v2: "一般为205美元，具体费用可能因考生所在国家或地区有所不同。",
      },
      p4: {
        title: "适用范围",
        desc: "GRE成绩广泛用于世界各地的研究生和商学院项目申请，包括美国、加拿大、英国、澳大利亚等国家的众多大学。",
      }
    },
    gmat: {
      p1: {
        title: "概述",
        desc: "GMAT（Graduate Management Admission Test）是由管理专业研究生入学考试委员会（GMAC）举办的一项标准化考试，主要用于评估申请商学院的学生在管理和商业领域的潜力。GMAT考试是全球商学院评估申请者的重要参考标准。",
      },
      p2: {
        title: "考试内容",
        desc: "GMAT考试由四个部分组成：",
        l1: "分析性写作评估（Analytical Writing Assessment, AWA）：",
        l2: "综合推理（Integrated Reasoning, IR）：",
        l3: "定量推理（Quantitative Reasoning, QR）：",
        l4: "语文推理（Verbal Reasoning, VR）：",
        v1: "评估考生的批判性思维和写作能力。",
        v2: "评估考生分析和评估多种数据来源的能力。",
        v3: "评估考生解决数学问题的能力。",
        v4: "评估考生理解和评价书面材料的能力。",
      },
      p3: {
        title: "考试时间及费用",
        l1: "·考试时间：",
        l2: "·考试费用：",
        v1: "全年多次举行，考生可以根据自己的时间安排选择合适的考试日期。",
        v2: "一般为250美元，具体费用可能因考生所在国家或地区有所不同。",
      },
      p4: {
        title: "适用范围",
        desc: "GMAT成绩广泛用于全球商学院的MBA和其他管理类项目的申请，包括美国、欧洲、亚洲等地区的知名商学院。",
      }
    }
  },
}
// 2024秋季课程
export const a_24 = {
  title: "2024年秋季课程",
  info: {
    title1: "√ 英国课程同步辅导课",
    desc1: "我们帮助您在具有挑战性的英国课程中取得优异成绩，包括 IGCSE、A-Level 和雅思考试。",
    desc2: "• 全面了解获得高分所需的关键概念",
    desc3: "• 提高考试技巧和策略的熟练程度",
    title2: "√ 大学课程衔接班",
    desc4: "大学课程衔接班专为即将进入大学的学生设计，旨在帮助他们顺利过渡到大学生活，并为未来的学术挑战做好充分准备。",
    desc5: "• 基础学科强化：通过系统学习大学基础课程，帮助学生打牢学术基础。",
    desc6: "• 学习技能提升：教授有效的学习策略和时间管理技巧，提高学生的学习效率。",
    desc7: "• 学术英语训练：针对学术写作、阅读和演讲进行专项训练，提升学生的英语水平。",
    desc8: "• 个性化指导：根据每位学生的需求和目标，提供个性化的学习计划和辅导。",
    title3: "√ G9 - G12同步辅导课",
    desc9: "专为需要额外支持的学生设计，帮助年轻的学习者为学术成就做好准备，包括 CEE、UKiset 和 SSAT 国际学校入学考试。",
    desc10: "• 全面覆盖各科目：无论是数学、科学、英语还是人文社科，我们的辅导课程都能满足学生的需求。",
    desc11: "• 学习进度跟踪：定期评估学生的学习进度，调整辅导策略，确保学术提升。",
    desc12: "无论你是想提高成绩、准备大学入学，还是只是想巩固学术基础，我们的秋季课程都将非常适合你。",
  },
}