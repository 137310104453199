export const home = {
  system_name: "International Education",
  login: "login",
  logout: "logout",

  banner: {
    title: "Pioneers and Leaders in International Education in the Guangdong-Hong Kong-Macao Greater Bay Area",
    text: "Chido Education helps students excel in international academic programs, including AP, IBDP, IGCSE, A-Level, HKDSE, IELTS, TOEFL, SAT, ACT, and GRE, and more.",
  },
  NEWS: "NEWS",
  plate_title: "ACADEMIC OFFERINGS",
  USA_Canada: "United States and Canada",
  ENG_HK: "The UK-Hong Kong Area",
  Fall_2024_Courses: "Fall 2024 Courses",
  
  about_us: {
    btn: "About us",
    title: "Top tutoring team with exclusive top teachers in the Greater Bay Area",
    desc: "Chido Education, headquartered in Tianhe District, Guangzhou, has swiftly become a leader in high-end international education in Southeastern China. Guided by the philosophy of “Staying True to the Original Aspiration, Pursuing Excellence Step by Step,” Chido was co-founded by mentors from top 30 QS-ranked universities, including MIT, Johns Hopkins, Columbia, Stanford, Oxford, and NUS. The company follows an “Elites Nurturing Elites” model, helping students achieve their goals and gain admission to top global universities.",
  },
  course_desc: {
    title: "Courses",
    desc1: "Chido Education offers specialized course planning for students applying to top universities in the U.S., U.K., Canada, Singapore, Australia, Hong Kong, and other regions. Based on students' academic and career development needs, we provide essential course training.",
    desc2: "Chido’s courses are divided into three main categories: standardized tests, international subjects, and competition challenges. Standardized tests and international subjects aim to help students meet admission requirements of their target institutions. Competition challenges, an exclusive feature of Chido, are designed to help top students reach their ideal schools. These courses not only improve students' admission chances but also prepare them for undergraduate honors programs and advanced studies.",
  },
  feedback: {
    title: "Student feedback",
    feedback1: "“Thanks to the guidance of Victor from Chido! In NEC National Economics Challenge Asia Finals, my daughter’s team achieved three Top 10 honors! If we continue participating in AS next year, we hope to keep learning from him. The entire process has been incredibly beneficial for the kids.”",
    feedback1f: "—— Chloe’s Mom (Competition Challenge)",
    feedback2: "“From making it to the China Finals to securing a CTB Harvard spot and winning third place globally, I am so grateful to have met Teacher Senqi. His guidance is excellent!”",
    feedback2f: "—— Chris’s Mom (Competition Challenge)",
    feedback3: "“Joe is very fortunate to have encountered amazing teachers from Chido! Their passion for teaching, responsible attitude towards students, and professional instruction often create a magical effect, leading Joe to achieve results beyond expectations.”",
    feedback3f: "—— Joe’s Mom (International Subjects)",
    feedback4: "“Transitioning from a public middle school to a private international high school was challenging. When my child first joined Chido in freshman year, his performance in English and other subjects was lagging. However, Chido’s Advisor Daniel provided valuable advice on course selection and studying. At the same time, teaching assistant May closely monitored his progress and promptly addressed any issues. After a year of study at Chido, my child is now enthusiastic about learning and looking forward to positive outcomes in the future!”",
    feedback4f: "—— ZX’s Mom (International Subjects, Course Planning)",
    feedback5: "“After starting his new college life at UC Berkeley, Arthur often reminisces about the time he spent learning political writing with Teacher Jack. He believes those writing courses provided a solid foundation for his current studies in international relations.”",
    feedback5f: "—— Arthur’s Mom (Competition Challenge)",
    feedback6: "“Even on weekends without classes, Mary loves going to Chido. There, she met many excellent peers, shared common interests, and discussed school selection and course issues. I am very thankful to Chido’s teachers and other students for supporting Mary through the stressful application process.”",
    feedback6f: "—— Mary’s Mom (Competition Challenge, International Subjects)",
  },
  footer: {
    ACADEMIC_OFFERINGS: "ACADEMIC OFFERINGS",
    Synchronous_Tutorial: "Synchronous Tutorial",
    CONSULTING: "CONSULTING",
    Senior_Lecturer: "Senior Lecturer",
    Teachers: "Teachers",
    Achievement: "Achievement",
    about_us: "ABOUT US",
  },
}
export const menu = {
  international_curricula: "International Curricula",
  discipline_competition: "Competition Challenge",
  standardized_tests: "Standardized Tests",
  resource: "Resource Center",
  about_us: "About Us",
  contact_us: "Contact Us",

  Science_and_Technology: "Science and Technology",
  Arts_and_Languages: "Arts and Languages",
  Social_Sciences_And_Economics: "Social Sciences and Economics",

  News: "What's New",
  simulation_test: "Online Simulation Test",
  
  Contact_Way: "Contact Way",
  Online_Consult: "Consult Now",
  Join_Us: "Join Us",
}

export const news = {
  type1: "Good news",
  type2: "Blogs",
  type3: "Events",
  emptyStr: "No information"
}

export const about_us = {
  title: "About us",
  company_intro: {
    title: "Company Introduction",
    desc: "Since its establishment, Chidao Education has adhered to the educational philosophy of “Upholding a Passionate Commitment and Pursuing Excellence with Perseverance,” steadily growing into a pioneer and leader in high-end international education within the Greater Bay Area (Guangdong-Hong Kong-Macao). Headquartered in Tianhe District, Guangzhou, Chidao Education's co-founders and chief mentors are graduates of top-tier universities, ranked among the QS Top 30 and US Top 20, including prestigious institutions such as MIT, Johns Hopkins University, Columbia University, Stanford University, Oxford University, and the National University of Singapore. Chidao Education has truly implemented an “elite fostering elite” model, allowing students to stay focused on their goals and follow mentors from top universities toward admission into the world's best institutions.",
  },
  teacher: {
    title: "Senior Lecturer",
    edu: 'Educational background',
    wok: 'Work experience',
    ach: 'Tutor achievements',
    te1: {
      name: "Senqi",
      edu1: "Bachelor's degree in Philosophy from Wuhan University",
      edu2: "Dual Master's degrees in Economics and International Affairs from Peking University and Columbia University",
      wok: "Worked at leading U.S. think tanks: the Brookings Institution, the Center for Strategic and International Studies (CSIS), and the German Marshall Fund; formerly a correspondent at Phoenix TV's Washington bureau, currently an analyst at a top foreign risk consultancy.",
      ach: "Worked at leading U.S. think tanks: the Brookings Institution, the Center for Strategic and International Studies (CSIS), and the German Marshall Fund; formerly a reporter at Phoenix Television's Washington bureau, and currently an analyst at a top foreign investment risk consulting firm."
    },
    te2: {
      name: "Junyu",
      edu1: "Master's in History and Archaeology, University of London, UK",
      edu2: "Master's in Curating and Collections, Chelsea College of Arts",
      edu3: "Bachelor's in Economics, University of Rhode Island, USA",
      wok: "Served as a specialist consultant in the Asia department at the renowned global antique auction company Bennet Deaks in the UK. Previously worked as an expert in artifact appraisal and evaluation at Zaiyi Technology in Beijing. Contributed over 20 articles to well-known public art media platforms in the antique and art industry, accumulating more than 100,000 views. Published three scholarly editorials in the Zhongshan Daily.",
      ach: "Guided students in theology, politics, history, economics, and interdisciplinary Junior competitions related to John Locke's works, with students achieving first, second, and third place awards, the CTB International Third Prize, a national gold medal (top five), and recording a global top 15 finish in the FBL competition."
    },
    te3: {
      name: "Dr.Song",
      edu1: "Spent five years studying in the UK and earned a Ph.D. in Computer Science from Imperial College (IC). ",
      edu2: "Currently serving as a professor in the Computer Science Department at South China Agricultural University.",
      wok: "Participated in and led more than 10 national and provincial research projects, published over 50 papers, and holds multiple invention patents and software copyrights. A senior member of the China Computer Federation (CCF) and a board member of the Guangdong Computer Society. Certified as a Microsoft Certified Professional (MCP) and internationally certified by IBM. In 2009, obtained certification as a Sun Microsystems Java Trainer (SCJP Trainer). Initiated the Java Programming Competition in 2010, serving as the secretary-general for seven years, with participation from over 600 universities.",
    },
    te4: {
      name: "Dr.Chen",
      edu1: "Bachelor's in Bioengineering, Wuhan University",
      edu2: "Master's in Bioengineering, University of Michigan, Ann Arbor",
      edu3: "Ph.D. in Developmental Biology, University of Chinese Academy of Sciences",
      wok: "Previously served as a graduate instructor for biophysics lab courses at the University of Michigan. Has extensive teaching experience in A-Level, AP, IG, and IB math, physics, and biology, providing customized teaching content for students of different levels and ages. Specializes in guiding students in biophysics and math competitions and improving their scores.",
      ach: "Every student taught has reached or exceeded their target scores. The student body includes students from prestigious schools such as Guangzhou Zhixin High School, Guangdong Experimental High School, BGY International School, Guangya School, Guangzhou Foreign Language School, and ULC. Achieved an 85% A* rate and an 85% 5-score rate. For three consecutive years, students won first prizes in HiMCM, as well as gold medals in PhysicsBowl and BPhO, with an average score of 78 in PAT.",
    },
  },
  team: {
    title: "Our Teachers",
    part1: {
      title: "Where do our teachers come from?",
      desc: "Chido's academic team consists of international teachers from the United States, the United Kingdom, Canada, Hong Kong, China, and other regions around the world. We take immense pride in the diversity of our team. Our educators possess extensive expertise in their respective fields, staying updated with the latest subject knowledge, researching curriculum changes, and continuously improving their teaching skills. They adapt their teaching methods to accommodate individual differences, ensuring tailored and effective instruction.",
    },
    part2: {
      title: "The following are some of the universities that our teachers come from:",
      us: {
        name: 'US',
        s1: "Harvard University",
        s2: "Massachusetts Institute of Technology (MIT)",
        s3: "Stanford University",
        s4: "Columbia University",
        s5: "Yale University",
        s6: "Duke University",
        s7: "Johns Hopkins University",
        s8: "California Institute of Technology (Caltech)",
        s9: "Brown University",
        s10: "University of Washington",
        s11: "Michigan State University",
      },
      uk: {
        name: "UK",
        s1: "University of Oxford",
        s2: "University of Cambridge",
        s3: "Imperial College London",
        s4: "London School of Economics and Political Science (LSE)",
        s5: "Durham University",
        s6: "University of Warwick",
      },
      hk: {
        name: "HK",
        s1: "The University of Hong Kong (HKU)",
        s2: "The Chinese University of Hong Kong (CUHK)",
      },
      sg: {
        name: "SG",
        s1: "Nanyang Technological University (NTU)",
        s2: "National University of Singapore (NUS)",
      },
    }
  },
  cases: {
    title: "Success cases of scoring points",
    t1: {
      name: "*Xia Yi",
      sc: "Student at Shanghai Guanghua College Cambridge International Centre, Grade 10",
      aw: "NEC Competition, Gold Medal",
    },
    t2: {
      name: "*Li Yuan",
      sc: "Student at Guangzhou Country Garden School, Grade 11",
      aw: "TOEFL 117",
    },
    t3: {
      name: "*Yin Han",
      sc: "Student at Guangzhou Country Garden International School, Grade 11",
      aw: "Shortlisted for NYT Summer Reading and Writing Competition",
    },
    t4: {
      name: "*Sun Xuan",
      sc: "Student at Shenzhen College of International Education, Grade 11",
      aw: "USABO Biology Competition, Gold Medal",
    },
    t5: {
      name: "*Lu Yi",
      sc: "Student at Guanghua Cambridge International School, Grade 11",
      aw: "BBO Competition, Gold Medal",
    }
  }
}

export const contact_us = {
  title: "Contact us",
  location: "38th Floor, Hao CITIC Plaza, 233 Tianhe North Road, Tianhe District, Guangzhou",
  btn: "Online Consultation",
}

export const consult = {
  title: "Online Consultation",
  contact: {
    title: "Main School",
    loc: {
      title: "Addresses",
      add1: "Guangzhou",
      add2: "Tianhe District",
      add3: "No.233 Tianhe North Road",
      add4: "38/F, CITIC Plaza",
    },
    opn: {
      title: "Opening Hours",
      op1t: "Monday to Friday",
      op1v: "10am - 6pm",
      op2t: "Saturday & Sunday",
      op2v: " 10am - 8pm",
    },
    con: {
      title: "Contact Information",
      tel: "Tel: 18028800860",
      eml: "Email: chido_public@outlook.com"
    }
  },
  form: {
    title: "Consult Form",
    desc: "Thank you very much for your interest in our program. We will respond to your inquiry within one business day.",
    name: "Name",
    ema: "E-Mail",
    tel: "Phone Number",
    cou: "Enquire about a course",
    com: "Message",
    namer: "Please enter name.",
    telr: "Please enter phone number.",
    emailr: "Please enter email.",
    btn: "Submit",
    sus: "Submit success",
    subdou: "Do not operate frequently",
  }
}

// 加入我们
export const join_us = {
  title: "Join Us",
  btn: "Detailed introduction",
  p1: {
    title: "Chido Education's career development path",
    desc1: "We are committed to providing our employees with a clear and productive career development path. Whether you are a newcomer just entering the workforce or an experienced professional, we offer tailored development plans for you. Through continuous training, mentoring, and career guidance, we help our employees achieve their personal and professional goals.",
    desc2: "Join Chido and open a new chapter in your career as we create a bright future together.",
  },
  p2: {
    title: "Are You the Right Fit for Chido?",
    desc: "We are looking for passionate, creative, and highly skilled teachers to join our team. At Chido, we value the professional growth of our teachers and provide abundant training resources and development opportunities for every educator. If you are passionate about education and eager to work in a dynamic and collaborative environment, we invite you to join us in shaping the future of the next generation. We are currently seeking candidates for the following positions:",
    s1: {
      title: "Subject Teachers:",
      j1: "· Mathematics",
      j2: "· Economics/Business",
      j3: "· Biology",
      j4: "· Geography",
      j5: "· History",
      j6: "· Chemistry",
      j7: "· Biology",
    },
    s2: {
      title: "Other Positions:",
      j1: "· Online New Media Specialist",
      j2: "· International Subject Offline Channel Manager",
      j3: "· International Curriculum Consultant (Level 1)",
    }
  }
}

// 职位详情
export const job_o = {
  title: "Job Opening",
  btn: "Continue reading",
  j1: {
    title: "International Curriculum Consultant",
    desc: "The key focus at Chido is education. We believe that academic success and high-quality academic planning are closely intertwined. Over the years, our educational consultants have helped countless students gain admission to elite schools around the world. If you are interested in improving your skills and excelling beyond your professional field.",
    tag: "Position available, Non-teaching",
  },
  j2: {
    title: "Marketing Manager",
    desc: "At Chido, education is our top priority. We believe that academic success and high-quality academic planning complement each other. Over the years, Chido has helped countless students achieve their academic goals and gain admission to elite schools around the world. If you are interested in improving your skills and excelling beyond your professional field in a multicultural and team-oriented environment, we welcome you to join us!",
    tag: "Position available, Non-teaching",
  },
  j3: {
    title: "Politics Teacher",
    desc: "Chido International Education is a leading educational service provider in the Guangdong-Hong Kong-Macao Greater Bay Area. We offer various courses that cover a wide range of exam systems, including IB, AP, I/GCSE, GCE - A Level, SAT, TOEFL, IELTS, entrance exams, and Hong Kong local curricula.",
    tag: "Position available, Teaching",
  },
  j4: {
    title: "IB/AP Subject Teacher",
    desc: "The key focus at Chido is education. We believe that academic success and quality teaching go hand in hand. Working as a subject teacher at Chido is a challenging but rewarding experience. There is nothing more gratifying than having a positive impact on students' lives. We are looking for a subject teacher with the following qualities.",
    tag: "Position available, Teaching",
  },
  j5: {
    title: "IELTS/TOEFL Teacher",
    desc: "The key focus at Chido is education. We believe that academic success and quality teaching go hand in hand. Working as a subject teacher at Chido is a challenging but rewarding experience. There is nothing more gratifying than having a positive impact on students' lives. We are looking for a subject teacher with the following qualities.",
    tag: "Position available, Teaching",
  },
}

export const job_d = {
  desc1: "If interested, please submit ",
  desc2: "Note: Shortlisted applicants will be invited by HR staff for an interview.",
  btn: "your application and relevant documents on our recruitment page.",
  j1: {
    title: "International Curriculum Consultant",
    p1: {
      title: "Selection Criteria:",
      desc1: "1.Team-oriented, highly adaptable, able to foster a positive team collaboration atmosphere;",
      desc2: "2.Capable of breaking down and solving problems through quantitative and qualitative analysis and thinking;",
      desc3: "3.Able to independently plan and complete assigned tasks within team projects;",
      desc4: "4.Strong communication and writing skills in both Chinese and English are preferred (this applies to fresh graduates only).",
    },
    p2: {
      title: "Job Responsibilities:",
      desc1: "1.Manage the company’s client resources, actively follow up with clients, and communicate with students via WeChat or in person to understand their needs and provide consulting services;",
      desc2: "2.Quickly analyze students' needs and design suitable plans for them, assess their learning situations, develop learning strategies and plans, guide them through the enrollment process, facilitate contract signing, and handle subsequent follow-ups;",
      desc3: "3.Proactively maintain relationships with potential clients and expand the company’s brand reputation within the industry;",
      desc4: "4.Complete the sales tasks assigned by the branch on time and meet quality standards, and accomplish the work assigned by superiors.",
    },
    p3: {
      title: "Skill Enhancement:",
      desc: "Chido is an employer willing to “invest” in your career development. As an international curriculum consultant, you can receive training and guidance in the following areas to improve your overall capabilities:",
      desc1: "1.Identify issues and take appropriate action to resolve them;",
      desc2: "2.Use quantitative and qualitative methods to break down complex information, clearly outline key points, and provide recommendations;",
      desc3: "3.Collaborate effectively with diverse teams to find optimal solutions and mobilize the team;",
      desc4: "4.Build strong client relationships based on mutual trust to better serve them;",
      desc5: "5.Communicate effectively with colleagues, clients, and management in a mature and appropriate manner;",
      desc6: "6.Shape your leadership style, fully unleashing your passion, strengths, and value.",
    }
  },
  j2: {
    title: "Market Manager",
    p1: {
      title: "Selection Criteria:",
      desc1: "1.Responsible for developing and maintaining key clients in the region, conducting project collaborations, and ensuring good relationships;",
      desc2: "2.Assist in building a network of high schools (both general and international) and undergraduate institutions, maintaining them effectively, and developing stable resource supply;",
      desc3: "3.Plan, promote, and execute market activities within the target market;",
      desc4: "4.Monitor the target market and clients, conduct research, organize and analyze findings, keep abreast of the latest market trends, and participate in the company's market decisions;",
      desc5: "5.Cooperate with the company's market share direction, accurately convey the company's values, and act as a bridge between the company, clients, and collaborators.",
    },
    p2: {
      title: "Job Requirements:",
      desc1: "1.Possess a college degree or higher in marketing, communications, public relations, or related fields;",
      desc2: "2.Industry experience is preferred; fresh graduates will also be considered;",
      desc3: "3.Proficient in Office software;",
      desc4: "4.Proficiency in Adobe Illustrator, Photoshop, InDesign, or video editing software is preferred;",
      desc5: "5.Strong sense of responsibility, analytical skills, creativity, and passion for marketing;",
      desc6: "6.Excellent teamwork spirit, and good written and spoken Chinese communication skills.",
    },
    p3: {
      title: "Benefits:",
      desc1: "1.Work five days a week in a dynamic, multicultural environment;",
      desc2: "2.Prime office location in downtown Guangzhou;",
      desc3: "3.Comprehensive training and professional development plans;",
      desc4: "4.Career development opportunities in a leading market organization;",
      desc5: "5.Holiday health gifts;",
      desc6: "6.Competitive salary and benefits.",
    }
  },
  j3: {
    title: "Political Science Teacher",
    p1: {
      title: "Job Responsibilities:",
      desc1: "1.Teach international civics and society courses;",
      desc2: "2.Design new courses based on the curriculum and develop teaching syllabi;",
      desc3: "3.Write student progress reports.",
    },
    p2: {
      title: "Requirements:",
      desc1: "1.Hold a degree in Chinese Language and Literature or Political Science;",
      desc2: "2.Intend to work long-term with an educational institution;",
      desc3: "3.Experience tutoring DSE civics or high school political science courses is preferred;",
      desc4: "4.Understanding of A-Level/IGCSE systems or an interest in teaching.",
    },
  },
  j4: {
    title: "AP/IB Subject Teacher",
    p1: {
      title: "Job Responsibilities:",
      desc1: "1.Teach AP and IB subjects in science, math, biology, history, geography, etc., at international schools across all grades. Ability to teach one or more subjects is acceptable;",
      desc2: "2.Collect up-to-date teaching data and suggest new teaching methods; regularly participate in company-organized teaching research discussions to improve teaching effectiveness and quality;",
      desc3: "3.Communicate students' progress promptly with the class teacher and teaching assistants to ensure timely follow-up;",
      desc4: "4.Complete other tasks assigned by the head of the teaching department.",
    },
    p2: {
      title: "Requirements:",
      desc1: "1.Bachelor’s degree or higher in the relevant subject, with an IELTS score of 7.0+; overseas study experience is preferred;",
      desc2: "2.Familiarity with AP/IB systems or an interest in teaching;",
      desc3: "3.Previous experience teaching in international schools is preferred; a teaching qualification (PGCE or related certification) is preferred;",
      desc4: "4.Passion for education, strong language skills, active and responsible work attitude, patience, professionalism, and strong work ethics.",
    },
  },
  j5: {
    title: "IELTS/TOEFL Teacher",
    p1: {
      title: "Requirements:",
      desc1: "1.Bachelor’s degree or higher in the relevant subject, with an IELTS score of 7.0+; overseas study experience is preferred;",
      desc2: "2.Familiarity with IELTS/TOEFL systems or an interest in teaching;",
      desc3: "3.Previous experience teaching in international schools is preferred; a teaching qualification (PGCE or related certification) is preferred;",
      desc4: "4.Passion for education, strong language skills, active and responsible work attitude, patience, professionalism, and strong work ethics.",
    },
  }
}

// 求职表单
export const job_f = {
  title: "Job Application Form",
  btn_p: "Last Step",
  btn_n: "Resume",
  btn_s: "Submit",
  form_r: "Please complete ",
  empty_tip: "If not applicable, please write “N/A”",
  Submit: {
    success: "Submit Success",
    confirm_t: "Confirm submit",
    confirm_d: "We will read your application as soon as possible",
    confirm_y: "Yes",
    confirm_n: "No",
  },
  A: {
    title: "Personal Information",
    name: "Name:",
    gender: "Gender:",
    gender1: "Male",
    gender2: "Female",
    birthday: "Date of Birth:",
    address: "Address:",
    phone: "Phone Number:",
    email: "Email:",
    target_job: "Position Applied For:",
    photo: "Recent Photo:",
  },
  B: {
    title: "Education and Training",
    add_c: "Create a new entry",
    add_c_t_t: "Confirm",
    add_c_t_d: "Would you like to delete this entry?",
    add_c_t: "Would you like to create a new entry?",
    add_c_y: "Yes",
    add_c_n: "No",
    img_title: "Copies of educational certificates and transcripts:",
    c: {
      title: "Other Education",
      school: "School Name:",
      address: "Location:",
      start_date: "From:",
      end_date: "To:",
      add_class: "Add a subject",
      class_title: "Subjects Taken and Grades:",
      class_sub: "Subject",
      class_val: "Grades",
    },
    c1: {
      title: "Secondary Education (High School) Required Fields:",
      class_title: "Subjects Taken and Grades:",
      class_sub: "Subject",
      class_val: "Grades",
    },
    c2: {
      title: "Higher Education (Bachelor's/Diploma followed by the highest degree):",
      class_title: "Degree/Diploma and Major:",
      class_sub: "Subject",
      class_val: "GPA/Percentage/Honors",
    }
  },
  C: {
    title: "Work Experience",
    title2: "Work Experience (in reverse chronological order)",
    add_c: "Create a new entry",
    company: "Employer Name and Address:",
    start_date: "From:",
    end_date: "To:",
    job: "Position:",
    pay: "Salary:",
    leave_reason: "Reason for Leaving:",
  },
  D: {
    title: "Other Information",
    arrival_date: "Earliest Availability Date:",
    target_pay: "Expected Salary (Monthly):",
  },
  E: {
    title: "Declaration",
    r: "Please agree statement",
    desc1: "Agreement and Declaration",
    desc2: "I hereby confirm and agree as follows:",
    desc3: "I certify that, to the best of my knowledge, all information provided in this application is true, complete, and I have not withheld any information that, if disclosed, may alter the completeness of this application.",
  },
}

// 国际课程
export const i_c = {
  title: "International Curricula",
  info: {
    desc1: "Chido Education's international programs prepare students for higher education by providing advanced academic knowledge, fostering critical thinking, and helping students develop essential skills such as time management and independent research.",
    desc2: "Students who excel in AP courses can earn college credit or enter honors or advanced courses in their first year, reducing future undergraduate study time and costs. A-Levels prepare students in the British system for three years of university study, enhancing their ability to handle challenging coursework and broadening their knowledge. The HKDSE provides Hong Kong students with multiple pathways and advantages for applying to universities in Hong Kong.",
    desc3: "At Chido, our teachers have a global perspective. They not only help students excel academically in specific subjects but also offer valuable advice for university admissions and future development.",
  },
  ap_ib: {
    desc1: "Welcome to Chido's Advanced Placement (AP) and International Baccalaureate (IB) programs!",
    desc2: "We are dedicated to providing comprehensive and high-quality international curriculum training to help students achieve academic excellence.",
    desc3: "AP courses, primarily used in the United States, allow students to study college-level courses and take exams in various subjects. High scores can earn college credit or advanced course placement. The IB program is internationally recognized and emphasizes critical thinking, cross-cultural understanding, and rigorous assessment across six subject groups, culminating in the IB diploma, which is highly valued by universities worldwide. Both programs are designed to prepare students for higher education by fostering academic excellence and global awareness.",
    btn_t: "Course",
    ap: {
      arc: "AP Excellent Student Cases",
      p1: {
        desc1: "With its excellent teaching strength and high-quality teachers, Chidao International Education has quickly established a leading AP course training system in Guangzhou and the Greater Bay Area. Since its establishment in 2022, the AP project has focused on in-depth research and practice of American college preparatory courses, providing students with high-quality academic support and tutoring services. In 2023, the admission rate of students in Chidao International Education's AP program at top American universities will increase significantly, becoming a highly respected AP training brand in the region.",
        desc2: "According to students' learning needs and personal characteristics, Chidao International Education has long-term opened a variety of class types such as boutique classes, full-time classes, high-end one-on-one classes, etc., and is committed to helping students achieve excellent results in AP exams and successfully enter the ideal United States and Top universities in the world.",
      },
      p2: {
        title: "What is AP",
        desc1: "AP is the American College Preparatory Course, which is hosted by the College Board. Students take college-level courses and take exams at the same level in high school.",
        desc2: "There are 38 subjects in total, which are mainly divided into 6 categories, namely art, English, history and social sciences, mathematics and computer science, natural sciences, and world languages and literature.",
        desc3: "Chinese students generally choose 3-6 courses to study, and the score of each AP course is judged based on the answers to the multiple-choice questions and free-answer questions.",
      },
      p3: {
        title: "The number of AP courses and the schools you want to take",
        desc: "The official has not given a clear recommendation on the number of courses you want to take, but based on the admissions data of American universities over the years:",
        desc1: "· At least 6-10 courses are required for the top 15 (Ivy League schools)",
        desc2: "· At least 5-7 courses are required for the top 30",
        desc3: "· At least 3-4 courses are required for the top 50",
      },
      p4: {
        title: "AP grading rules",
        desc1: "A 5-point system is used:",
        desc2: "5 = excellent, 4 = very good, 3 = qualified, 2 = barely qualified, 1 = unqualified. ",
        desc3: "Top-ranked universities require 4 or 5 points to exchange for college credits. Generally, 3 points or more are accepted by most schools",
      },
      p5: {
        title: "Benefits of taking AP courses:",
        l1: "Increase GPA",
        l2: "Admission basis for famous American universities",
        l3: "Short preparation period",
        l4: "Exchange for American university credits and save tuition",
        v1: "GPA is an important consideration for admission to famous American universities. On average, each AP course increases GPA by 0.1 points",
        v2: "AP courses are an important indicator for famous American universities to ensure that students dare to challenge academic difficulties and clarify the direction of academic development",
        v3: "Generally, it is a theoretical test without a practical part. Most of them can be completed and taken within one year",
        v4: "Thousands of universities and colleges around the world accept AP exams and grant university credits. One AP course can be exchanged for 3-5 university credits. The value of each credit for undergraduate private colleges in the United States is about 7,000 yuan",
      },
      p6: {
        title: "Course highlights",
        desc1: "Scientifically planned admission",
        desc2: "Top-notch teachers throughout the course",
        desc3: "Exclusive service system",
      },
    },
    ib: {
      p1: {
        desc1: "As a high-end international full-category subject training brand in the Greater Bay Area, Chidao International Education has not only achieved outstanding achievements in A-level and DSE projects, but also is committed to in-depth research and teaching practice of the IB curriculum.",
        desc2: "Since its establishment in 2022, the IB project has quickly formed and developed a strong professional teaching team, focusing on helping students achieve top results. Chidao has long opened IB boutique improvement classes, full-time off-campus classes and high-end one-on-one tutoring courses based on the different characteristics and needs of students. With its excellent teaching quality and strong faculty, Chidao International Education has become a leading institution for IB curriculum training in Guangzhou and the Greater Bay Area.",
      },
      p2: {
        title: "What is IB",
        desc1: "The International Baccalaureate (IB) is a globally recognized rigorous and comprehensive education program that aims to cultivate students' critical thinking, cross-cultural understanding and global vision. The IB curriculum is suitable for students aged 3 to 19, covering the Primary Years Program (PYP), the Middle Years Program (MYP), the University Preparatory Program (DP) and the Career-related Program (CP). Among them, the University Preparatory Program (DP) is particularly favored by top universities around the world.",
        desc2: "The uniqueness of the IB curriculum lies in that it not only focuses on academic performance, but also emphasizes the holistic development of students. Through a multidisciplinary integrated teaching method, IB students can explore in depth in six major subject groups and participate in core projects such as Theory of Knowledge (TOK), Extended Essay (EE) and Innovation, Action and Service (CAS). These learning experiences help students develop critical thinking skills, research skills and a sense of responsibility, so that they are fully prepared for future university studies and careers.",
      },
      p3: {
        title: "Course highlights:",
        desc1: "Globally recognized academic rigor",
        desc2: "Educational philosophy of holistic development",
        desc3: "Cross-cultural understanding and global vision",
      },
    },
  },
  ig_al: {
    desc1: "Welcome to Chido's IGCSE (International General Certificate of Secondary Education) and A-Level (Advanced Level) programs! ",
    desc2: "We are committed to providing comprehensive and high-quality international curriculum training to help students achieve academic excellence, with courses tailored for Edexcel, CIE, and AQA examination boards.",
    desc3: "IGCSE is typically taken by students aged 14 to 16 and covers a broad range of subjects, providing a solid foundation for further study. A-Level is more advanced and suited for students aged 16 to 18, focusing on in-depth study of specific subjects. A-Level qualifications are highly regarded by universities worldwide and are often a requirement for undergraduate programs. Both IGCSE and A-Level prepare students for higher education and career paths, fostering critical thinking and subject-specific knowledge.",
    btn_t: "Course",
    ig: {
      p1: {
        desc1: "As a premier international academic training brand in the Greater Bay Area, Chidao International Education has rapidly established itself as the only professional A-level teaching and research team in Guangzhou, thanks to its outstanding academic teaching capabilities and strong faculty. The A-level program was first established in 2022, and by 2023, it had become the leading professional training institution in Guangzhou and the Greater Bay Area, boasting a 78% acceptance rate into the UK's G5 universities in the 2023 admissions. ",
        desc2: "Chidao International Education is dedicated to in-depth research and practice for A-level examinations, offering a variety of classes tailored to students' unique characteristics and needs, including elite enhancement classes, full-time programs, and high-end one-on-one tutoring. The institution has also created a dedicated A-level brand—“Chidao A-level.”",
      },
      p2: {
        title: "What is the IGCSE Course?",
        desc1: "The International General Certificate of Secondary Education (IGCSE) is one of the most widely recognized curricula globally, designed for students aged 14 to 16. It is part of the Cambridge Global Examinations and is acknowledged in all English-speaking countries. ",
        desc2: "The IGCSE standard curriculum lasts two years, corresponding to grades G1 and G2. It offers over 70 subjects, including more than 30 languages. Core subjects include English, Mathematics, and Science, with additional elective subjects available based on students' needs and interests, laying the groundwork for future A-Level or IB studies.",
      },
      p3: {
        title: "Why Study the IGCSE Course? How Important is it?",
        l1: "Bridging the Gap to A-Level Courses:",
        v1: "The IGCSE course is essential for transitioning to the more challenging A-Level curriculum, which is crucial for university applications. The knowledge gained from IGCSE covers a broad spectrum, laying a solid foundation for the more in-depth study in the AS level.",
        l2: "Reference for University Applications: ",
        v2: "Increasingly, prestigious universities in the UK consider IGCSE results as a significant admission criterion, on par with predicted grades and final A-Level results.",
        l3: "Early Preparation for University Applications: ",
        v3: "In addition to compulsory subjects like Mathematics, English, and Science, students can choose subjects based on their interests, ranging from Social Sciences (typically Accounting, Business Studies, Economics, Sociology) to Arts and Technology (typically Computer Studies, Information and Communication Technology (ICT), Art and Design). Each subject taken in IGCSE results in a certificate, adding value to the student's academic profile.",
      },
      p4: {
        title: "Course Highlights",
        desc1: "Experienced instructors",
        desc2: "Personalized tutoring and support",
        desc3: "A wide range of subjects and research skills offered",
      }
    },
    al: {
      p1: {
        desc1: "The A-level program was first established in 2022, and in 2023 it became the professional training institution with the highest admission rate in Guangzhou and the Greater Bay Area. Among all students admitted in 2023, the admission rate of the UK G5 universities was as high as 78%.",
        desc2: "Chidao International Education is committed to in-depth research and practice of the A-level exam. According to the different characteristics and needs of students, it has long opened boutique improvement classes, full-time off-campus classes, high-end one-on-one classes, and established an exclusive A-level brand, “Chidao A-level”.",
      },
      p2: {
        title: "What is A-Level",
        desc: "AL covers the curriculum from high school to freshman year in the UK. The curriculum covers a wide range of topics. The AS stage is moderately difficult, and the A2 stage is challenging. Students generally need 1.5-3 years to complete all AL exams. During the application, students can submit the actual test and estimated scores to apply for the target school after completing the AS part (or some subject papers).",
      },
      p3: {
        title: "Two major examination boards",
        l1: "①CIE (Cambridge): ",
        l2: "②Edexcel: ",
        v1: "Single application to complete multiple AS or AL papers, science is difficult, liberal arts is moderate",
        v2: "Medium difficulty, each subject can be tested in separate papers, Pearson's AL examination product, admission reference is the highest and divided",
      },
      p4: {
        title: "Pre-level connection mode",
        l1: "1. Two years of IG connection, ",
        l2: "2. Pre mode, ",
        v1: "4 years of study in international high school, G1G2+ASA2.",
        v2: "which is to combine the two years of IG connection into one year of Pre, and then go to A-Level after Pre, and study in international school for 3 years, Pre+ASA2",
      },
      p5: {
        title: "Grading criteria：",
        desc1: "If the full score of a single subject is 600 points:",
        desc2: "A* is the best, the highest score for a single paper is A, E is passed, U is failed",
      },
      p6: {
        title: "Why should I study IGCSE? How important is IGCSE",
        l1: "The course is more structured and flexible: ",
        l2: "A lot of subjects to choose from: ",
        l3: "Also exam-based: ",
        l4: "You can acquire important abilities: ",
        v1: "A-level has a two-year curriculum, the first year is called AS, and the second year is called A2. It is generally recommended to take 4 courses for AS and 3 courses for A2. Unlike other curriculum systems, A-level does not require so many courses to disperse your attention, which is good news for students who are partial to certain subjects.",
        v2: "A-level has a lot of subjects to choose from. Generally, before choosing courses, the school will choose courses based on your direction of further study and future career goals, so that you can gain a greater competitive advantage.",
        v3: "A-level course assessment runs through the two-year study, but most subjects are exam-based, which is fair and objective. For students who are good at exams, it is easier to get good grades.",
        v4: "A-level is a preparatory course for British universities. The subject knowledge, learning methods and thinking methods, time management skills, etc. acquired in the A-level stage are an effective preparation for intense university studies.",
      },
      p7: {
        title: "Course highlights",
        desc1: "High-quality teaching and research team",
        desc2: "meeting the needs of different students",
        desc3: "diversified service content",
      },
    },
  },
  hk: {
    p1: {
      desc1: "The CHIDO DSE program was first established in 2022 and by 2023 had become the leading professional training institution with the highest admission rate in Guangzhou and the Greater Bay Area. In 2023, the program achieved an impressive 78% admission rate to Hong Kong's top eight universities and created its own exclusive DSE brand—“CHIDO DSE.”",
      desc2: "CHIDO International Education is a premier academic training brand in the Greater Bay Area, offering a comprehensive range of high-end international courses. Leveraging its unique academic teaching capabilities and a strong faculty, it quickly became the only professional DSE research and teaching team in Guangzhou, dedicated to the study and practice of the DSE exam. Based on the different characteristics and needs of students, CHIDO offers long-term courses such as premium enhancement classes, full-time intensive courses, and high-end one-on-one tutoring.",
    },
    p2: {
      title: "What is the DSE Exam?",
      desc1: "The DSE exam is divided into three categories:",
      l1: "Category A: ",
      v1: "4 core high school subjects (4 core subjects include Chinese Language, English Language, Mathematics, Citizenship and Social Development, along with 20 elective subjects).",
      l2: "Category B: ",
      v2: "Applied Learning Subjects. Candidates' results must be verified by the Examinations Authority and are reported in two levels: “Achieved” and “Achieved with Excellence.”",
      l3: "Category C: ",
      v3: "Other language subjects: French, German, Japanese, Spanish, etc.",
    },
    p3: {
      title: "Advantages of DSE",
      l1: "Flexible Exam Schedule: ",
      v1: "The HKDSE exam is scheduled between March and May each year, allowing students to take the exam without conflicting with the National College Entrance Examination (Gaokao).",
      l2: "Recognized by 300 Institutions: ",
      v2: "Nearly 300 higher education institutions worldwide accept students with HKDSE scores for admission.",
      l3: "Valid for 2 Years: ",
      v3: "HKDSE results are valid for two years. If the first-year scores are unsatisfactory, students can retake individual or all subjects in the second year, with the best scores from both years combined for final application results.",
      l4: "4 + 2 Free Choice: ",
      v4: "Subject selection flexibility. In addition to 4 compulsory subjects, students can choose 2-3 elective subjects based on their abilities and interests.",
    },
    p4: {
      title: "Course Highlights",
      desc1: "Expert faculty aligned",
      desc2: "Unique teaching system",
      desc3: "Seasoned academic management team",
    }
  },
}

export const d_c = {
  title: "Competition Challenge",
  info: {
    title1: "What are International Contests?",
    desc1: "International Contests are global events where, unlike Standardized Tests where students compete against a team of question writers, International Contests are person-to-person competitions in which students decide whether to participate or not to participate. In international competitions, students are challenged to go beyond themselves, there is no constant answer, and competitors are not ranked high or low based on their performance, but the organizers prefer to find out what percentage of all the participants are in the top (e.g. Top 5% of the world)",
    desc2: "Our Competitive Disciplines Program is designed to help students excel in a variety of national and international academic competitions. We provide professional guidance and systematic training in all major competition disciplines such as math, physics, chemistry, biology, computer science and more. Through our well-designed programs, students will comprehensively improve their subject knowledge and competition skills, and develop their logical thinking and innovation ability.",
    desc3: "We have a world-exclusive team of elite instructors, who are not only familiar with the question types and key points of various competitions, but also can customize the learning plan according to the characteristics of each student. Our program includes basic knowledge, explanation of competition question types, mock exams and guidance on answering techniques to help students excel in intense competitions.Join our competition discipline program and let's start the journey to the top of the academic world together, laying a solid foundation for your future study and career.",
    desc4: "The Kindle Competitions program hopes to empower students to explore uncharted academic territory and channel their interest in solving the world's critical and complex problems.",
    title2: "Kindle Competition Advantage Pros",
    ds1: "√  Industry's highest winning rate, 98% winning rate",
    ds2: "√  Exclusive global elite coaching team",
    ds3: "√  Exclusive competition consultants from top 10 universities provide accurate information and answer questions in real time.",
    ds4: "√  Frontier competition training experts to provide the latest, most efficient personalized courses",
    ds5: "√  Jointly organize teams with 80+ study abroad institutions and establish a reliable and safe competition resource sharing platform.",
  },
  c1: {
    title: "Science and Technology",
  },
  c2: {
    title: "Arts and Languages",
  },
  c3: {
    title: "Social Sciences and Economics",
  },
  sat: {
    title: "Science and Technology",
    c1: {
      title: "Mathematics and Modeling",
    },
    c2: {
      title: "computer science",
    },
    c3: {
      title: "Physical Chemistry Biology",
    },
  },
  aal: {
    c1: {
      title: "Writing and Speaking"
    }
  },
  sse: {
    c1: {
      title: "Economics and Finance"
    }
  }
}

// 美加地区
export const u_c = {
  title: "United States and Canada",
  info: {
    desc1: "Chido Education’s U.S. and Canadian curriculum programs are designed to help students fully adapt to and master the requirements and standards of North American education systems. We offer a comprehensive range of courses from middle school to high school, including English, Mathematics, Science, Social Studies, and electives, ensuring students achieve development and improvement in all areas.",
    desc2: "Our curriculum design follows the educational guidelines of the U.S. and Canada, focusing on developing students' critical thinking, creativity, and problem-solving skills. Through small-class teaching and personalized tutoring, our experienced teaching team provides customized study plans and support tailored to each student’s learning characteristics and needs, helping them achieve academic excellence.",
    desc3: "In addition to AP (Advanced Placement) and IB (International Baccalaureate) courses, as well as SAT and ACT preparation, Chido Education was authorized by Toronto International Academy (TIA) in 2023 to offer the OSSD (Ontario Secondary School Diploma) program. Through scientific planning, professional instruction, and comprehensive support services, Chido aims to enhance students' academic abilities and build a solid foundation for applying to top North American universities, while fostering good study habits.",
    desc4: "Join our U.S. and Canadian curriculum programs to open doors to top North American institutions and significantly enhance your child's academic competitiveness and overall quality.",
  }
}

// 英港地区
export const e_h = {
  title: "The UK-Hong Kong Area",
  info: {
    desc1: "Our UK and Hong Kong curriculum programs are dedicated to helping students adapt to and master the requirements and standards of British education, laying a solid foundation for their future academic paths. We offer a wide range of courses from middle school to high school, including English, Mathematics, Science, Humanities, and electives, ensuring comprehensive development across all subject areas.",
    desc2: "Our curriculum is designed to strictly follow the educational guidelines of the UK and Hong Kong, with a focus on fostering critical thinking, creativity, and independent learning skills. Through one-on-one teaching and personalized tutoring, our team of professional educators tailors study plans to meet each student's individual needs and characteristics, helping them achieve academic excellence.",
    desc3: "In addition to core subjects, we provide IGCSE (International General Certificate of Secondary Education) and A-Level courses, which not only enhance students' academic abilities but also support their applications to top universities worldwide. Simultaneously, we offer Hong Kong DSE (Diploma of Secondary Education) courses to provide multiple pathways for local students’ higher education.",
    desc4: "Furthermore, we offer training for standardized tests such as IELTS and UKCAT, helping students achieve excellent results in these crucial exams. Our programs not only focus on academic performance but also emphasize the development of students' overall competencies, ensuring they stand out in a competitive environment.",
    // desc5: "Join our UK-Hong Kong System program to open the door to world-renowned schools for your child, and improve their academic competitiveness and overall quality.",
  }
}

// 标化测试
export const s_t = {
  title: "Standardized Testing",










  info: {
    desc1: "Our standardized test courses are tailored for students preparing for exams such as IELTS, TOEFL, SAT, ACT, GRE, and GMAT. The goal is to help students achieve excellent results in these globally recognized tests, paving the way for entry into their desired universities.",
    title1: "IELTS and TOEFL Courses",
    desc2: "IELTS and TOEFL are the most common English language proficiency tests for applying to universities and colleges in English-speaking countries. Our IELTS and TOEFL courses are taught by a team of instructors with over 7 years of experience. The courses cover all four sections: listening, reading, writing, and speaking. Through systematic training and practice tests, we help students enhance their overall language skills, familiarize themselves with the test format, and master answering techniques to achieve their desired scores.",
    title2: "SAT and ACT Courses",
    desc3: "The SAT and ACT are critical standardized tests for university admission in the U.S. Our SAT and ACT courses cover subjects including mathematics, reading, writing, and scientific reasoning. The courses focus on test-taking strategies and time management, offering extensive practice and simulated exams to boost students' test-taking abilities, build confidence, and help them excel in the exams.",
    title3: "Features of Our Standardized Test Courses:",
    ds1: "One-on-One Instruction: Ensures personalized attention and guidance for each student.",
    ds2: "Full-Length Simulations: Regularly conducted practice tests to help students become familiar with the exam environment and pacing.",
    ds3: "Customized Tutoring: Personalized study plans and tutoring based on each student's weaknesses and needs.",
    ds4: "Abundant Resources: Provides a vast array of practice questions, preparatory materials, and study resources to help students prepare comprehensively.",
  },
  // IELTS_TOEFL
  ie_to: {
    p1: {
      desc: "Our IELTS and TOEFL language training courses are designed to help students improve their overall English listening, speaking, reading and writing skills to meet the challenges of international standardized tests. Through systematic teaching and extensive practice, our students are able to master test-taking skills and improve their language proficiency so that they can excel in the exams and lay a solid foundation for admission to their desired overseas institutions.",
    },
    p2: {
      title: "The three main advantages of our course are:",
      desc1: "1. Real super teachers in the industry, with more than 10 years of teaching experience and a combined score rate of 98%!!!!",
      desc2: "2. From planning to score the implementation of the “dual-teacher tutor system”, supervisors and master teachers to monitor the whole process!",
      desc3: "3.Specialized customized small class 2:1 intensive practice courses, high efficiency, fast scores, low cost!",
    },
    p3: {
      l1: "IELTS course: ",
      v1: "comprehensively improve listening, speaking, reading and writing skills, set up courses for academic and immigration needs.",
      l2: "TOEFL course: ",
      v2: "focuses on improving students' academic English skills, especially suitable for students preparing to study in the United States.",
      l3: "Course content: ",
      v3: "Includes listening practice, speaking interaction, reading comprehension, and writing skills.",
      l4: "Teaching methods: ",
      v4: "small group classes, one-on-one tutoring, practice tests, etc.",
    }
  },
  // SAT_ACT
  sat_act: {
    p1: {
      desc: "The SAT and ACT are important tests for college admissions in the United States, and each has a different structure and focus. Our test preparation programs are designed to help students fully understand and master the knowledge and skills needed to improve their overall test scores. Moreover, SAT and ACT scores are one of the most important criteria for college admissions. Good test scores can help students get into their desired colleges and receive scholarships.",
    },
    p2: {
      title: "Course Structure",
      title1: "Our courses are organized into different modules, including:",
      l1: "√ Math: ",
      v1: "covering algebra, geometry, data analysis, etc.",
      l2: "√ Reading: ",
      v2: "improves students' reading comprehension skills and analyzes complex texts.",
      l3: "√ Writing: ",
      v3: "teaches students how to write clearly and powerfully.",
      l4: "√ Scientific Reasoning (ACT-specific): ",
      v4: "helps students understand and analyze scientific information.",
    },
    p3: {
      title: "Course Schedule",
      desc1: "√ Detailed lesson plans and timelines for each module.",
      desc2: "√ Mock exams and review stages are scheduled to ensure that students are able to thoroughly review and self-test before the exam.",
    },
    p4: {
      title: "Course Highlights",
      desc1: "Absorb and utilize effective test-taking techniques such as interpretation and elimination",
      desc21: "Be familiar with unique test formats",
      desc22: "Choose the right one for you (ACT / SAT)",
      // desc2: "Familiarize yourself with unique test formats and choose the right one for you (ACT / SAT)",
      desc3: "Accurately apply subject matter knowledge to test questions",
    },
  },
  // GRE/GMAT
  gr_gm: {
    desc1: "The GRE (Graduate Record Examinations) &IGMAT (Graduate Management Admission Test) are two standardized tests primarily used for graduate school admissions.Test) are two standardized tests used primarily for graduate school admissions. the GRE is used for a wide range of graduate program applications, while the GMAT is often the preferred test for business school and MBA programs. Both include math, verbal, and analytical writing sections, but the GMAT also includes an integrated reasoning section.",
    desc2: "√ Suitable for students planning to pursue graduate programs in MBA, management, economics, engineering, psychology, social sciences, and other fields.",
    gre: {
      p1: {
        title: "Overview",
        desc: "The Graduate Record Examination (GRE) is an international graduate admissions test administered by the Educational Testing Service (ETS) that measures language, math, and analytical writing skills, and is one of the most important criteria used by universities around the world to evaluate applicants for graduate study.",
      },
      p2: {
        title: "Test Content",
        desc: "The GRE is divided into General Test and Subject Tests.",
        s1: {
          title: "The General Test consists of three parts:",
          l1: "1. Verbal Reasoning (Verbal Reasoning): ",
          v1: "assesses the candidate's vocabulary and comprehension skills.",
          l2: "2. Mathematical Reasoning (Quantitative Reasoning): ",
          v2: "assesses the candidate's basic mathematical skills and ability to analyze data.",
          l3: "3. Analytical Writing: ",
          v3: "assesses candidates' critical thinking and analytical writing skills.",
        },
        s2: "Specialized exams include subjects such as Biology, Chemistry, Literature, Mathematics, Physics, Psychology, etc., which are mainly used to assess candidates' knowledge and skills in specific areas."
      },
      p3: {
        title: "Exam Duration and Fees",
        l1: "·Exam Time: ",
        l2: "·Exam Fee: ",
        v1: "It is held several times throughout the year, and candidates can choose the appropriate exam date according to their own schedule.",
        v2: "Generally $205, the exact fee may vary depending on the candidate's country or region.",
      },
      p4: {
        title: "Scope of Application",
        desc: "GRE scores are widely used for applications to graduate and business school programs around the world, including numerous universities in the United States, Canada, the United Kingdom, Australia, and other countries.",
      }
    },
    gmat: {
      p1: {
        title: "OVERVIEW",
        desc: "The GMAT (Graduate Management Admission Test) is a standardized test administered by the Graduate Management Admission Council (GMAC) to assess the potential of students applying to business schools in the fields of management and business.The GMAT is an important reference for business schools around the world in evaluating applicants.",
      },
      p2: {
        title: "Test Content",
        desc: "The GMAT consists of four sections:",
        l1: "Analytical Writing Assessment (AWA): ",
        l2: "Integrated Reasoning (Integrated Reasoning, IR): ",
        l3: "Quantitative Reasoning (QR): ",
        l4: "Verbal Reasoning (VR): ",
        v1: "Assesses the candidate's critical thinking and writing skills.",
        v2: "Assesses the candidate's ability to analyze and evaluate multiple sources of data.",
        v3: "Assesses candidates' ability to solve mathematical problems.",
        v4: "Assesses candidates' ability to understand and evaluate written material.",
      },
      p3: {
        title: "Exam Duration and Fees",
        l1: "·Exam Dates：",
        l2: "·Exam Fee：",
        v1: "Held several times throughout the year, candidates can choose the appropriate exam date according to their schedule.",
        v2: "Generally $250, the exact fee may vary depending on the candidate's country or region.",
      },
      p4: {
        title: "Scope of Application",
        desc: "GMAT scores are widely used for applications to MBA and other management programs at business schools around the world, including well-known business schools in the United States, Europe, Asia, and other regions.",
      }
    }
  },
}

// 2024秋季课程
export const a_24 = {
  title: "Fall 2024 Courses",
  info: {
    title1: "√ Synchronized Tutoring for UK Courses",
    desc1: "We help you excel in challenging UK courses including IGCSE, A-Level and IELTS exams.",
    desc2: "• Gain a comprehensive understanding of the key concepts needed to achieve high scores",
    desc3: "• Increased proficiency in exam techniques and strategies",
    title2: "√ University Preparation",
    desc4: "The University Bridging Program is designed for university-bound students to help them make a smooth transition to university life and prepare them for the academic challenges ahead.",
    desc5: "• Basic Subjects Enhancement: Helps students build a strong academic foundation through systematic study of basic college courses.",
    desc6: "• Study Skills Enhancement: Teaching effective study strategies and time management skills to improve students' learning efficiency.",
    desc7: "• Academic English training: Specialized training in academic writing, reading and speaking to improve students' English proficiency.",
    desc8: "• Individualized coaching: Provide personalized study plans and coaching based on each student's needs and goals.",
    title3: "√ GG9- G12 Synchronized Tutoring",
    desc9: "Designed for students who need extra support to prepare young learners for academic achievement, including CEE, UKiset and SSAT international school entrance exams.",
    desc10: "• Comprehensive coverage of all subjects: Whether it's Math, Science, English or Humanities, our tutoring programs are designed to meet the needs of students.",
    desc11: "• Progress tracking: We regularly assess students' progress and adjust our tutoring strategies to ensure academic improvement.",
    desc12: "Whether you're looking to improve your grades, prepare for college admission, or just strengthen your academic foundation, our fall program will be a great fit for you.",
  },
}
