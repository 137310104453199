import {
  userLogin,
  userRegister,
  userEdit,
  userPwd,
  userInfo,
  userPhoneVerify,
  adminUploadImage,
} from '@/api/user'
import { removeToken } from '@/utils/auth'

export default {
  // 修改语言
  async setLang({ commit }, data) {
    commit('SET_LANG', data)
  },
  // 登录
  async userLogin({ }, data) {
    return userLogin(data)
  },
  // 注册
  async userRegister({ }, data) {
    return userRegister(data)
  },
  // 编辑
  async userEdit({ }, data) {
    return userEdit(data)
  },
  // 修改密码
  async userPwd({ }, data) {
    return userPwd(data)
  },
  // 手机验证码
  async userPhoneVerify({ }, data) {
    return userPhoneVerify(data)
  },
  async userLogout({ commit }, data) {
    removeToken()
    commit('SET_NAME', '')
    commit('SET_ROLE', '')
    commit('SET_USER_INFO', null)
    return {
      code: 0
    }
  },
  async userInfo({ commit }, data) {
    const ret = await userInfo(data)
    if (ret.code === 0 && ret.data) {
      const { name, role } = ret.data
      commit('SET_NAME', name)
      commit('SET_ROLE', role)
      commit('SET_USER_INFO', ret.data)
    }
    return ret
  },


  async adminUploadImage({ }, data) {
    return adminUploadImage(data)
  },
}
