import Vue from 'vue'
import * as echarts from 'echarts'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css'; // 引入Element UI样式

import L from "leaflet";
import "leaflet/dist/leaflet.css";

import 'normalize.css/normalize.css'
import '@/styles/index.less'
import App from './App.vue'
import store from './store'
import router from './router'

import './permission'
import { i18n } from './i18n/index' //国际化

Vue.config.productionTip = false

Vue.use(ElementUI);
Vue.prototype.$echarts =echarts

Vue.L = Vue.prototype.$L = L;
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

// new Vue({
//   render: h => h(App),
// }).$mount('#app')

new Vue({
  el: '#app',
  router,
  store,
  i18n,
  render: h => h(App)
})
