import Vue from 'vue'
import Router from 'vue-router'
import adLayout from '@/layout/ad'
import usLayout from '@/layout/us'

Vue.use(Router)

export const constantRoutes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/ad',
    redirect: '/ad/subject'
  },
  {
    path: '/404',
    component: () => import('@/views/error-page/404'),
    hidden: true
  },
  {
    path: '/401',
    component: () => import('@/views/error-page/401'),
    hidden: true
  }
]

/**
 * permissionRoutes
 * a base page that have permission requirements
 */
export const permissionRoutes = [
  {
    path: '/',
    component: usLayout,
    children: [
      {
        name: 'home',
        path: '/home',
        hidden: true,
        component: () => import('@/views/us/home'),
        meta: {
          title: '首页'
        }
      },
      {
        name: 'ic',
        path: '/ic',
        hidden: true,
        component: () => import('@/views/us/i-c'),
        meta: {
          title: '国际课程'
        }
      },
      {
        name: 'hkdse',
        path: '/hkdse',
        hidden: true,
        component: () => import('@/views/us/i-c/hkdse'),
      },
      {
        name: 'apib',
        path: '/apib',
        hidden: true,
        component: () => import('@/views/us/i-c/ap-ib'),
      },
      {
        name: 'ap',
        path: '/ap',
        hidden: true,
        component: () => import('@/views/us/i-c/ap-ib/ap'),
      },
      {
        name: 'ib',
        path: '/ib',
        hidden: true,
        component: () => import('@/views/us/i-c/ap-ib/ib'),
      },
      {
        name: 'igal',
        path: '/igal',
        hidden: true,
        component: () => import('@/views/us/i-c/ig-al'),
      },
      {
        name: 'ig',
        path: '/ig',
        hidden: true,
        component: () => import('@/views/us/i-c/ig-al/ig'),
      },
      {
        name: 'al',
        path: '/al',
        hidden: true,
        component: () => import('@/views/us/i-c/ig-al/al'),
      },
      {
        name: 'dc',
        path: '/dc',
        hidden: true,
        component: () => import('@/views/us/d-c'),
        meta: {
          title: '竞赛学科'
        }
      },
      {
        name: 'sat',
        path: '/sat',
        hidden: true,
        component: () => import('@/views/us/d-c/s-a-t'),
      },
      {
        name: 'aal',
        path: '/aal',
        hidden: true,
        component: () => import('@/views/us/d-c/a-a-l'),
      },
      {
        name: 'sse',
        path: '/sse',
        hidden: true,
        component: () => import('@/views/us/d-c/s-s-e'),
      },
      {
        name: 'uc',
        path: '/uc',
        hidden: true,
        component: () => import('@/views/us/u-c'),
        meta: {
          title: '美加地区'
        }
      },
      {
        name: 'eh',
        path: '/eh',
        hidden: true,
        component: () => import('@/views/us/e-h'),
        meta: {
          title: '英港地区'
        }
      },
      {
        name: 'st',
        path: '/st',
        hidden: true,
        component: () => import('@/views/us/s-t'),
        meta: {
          title: '标化测试'
        }
      },
      {
        name: 'ieto',
        path: '/ieto',
        hidden: true,
        component: () => import('@/views/us/s-t/ie-to'),
      },
      {
        name: 'satact',
        path: '/satact',
        hidden: true,
        component: () => import('@/views/us/s-t/sat-act'),
      },
      {
        name: 'grgm',
        path: '/grgm',
        hidden: true,
        component: () => import('@/views/us/s-t/gr-gm'),
      },
      {
        name: 'gre',
        path: '/gre',
        hidden: true,
        component: () => import('@/views/us/s-t/gr-gm/gre'),
      },
      {
        name: 'gmat',
        path: '/gmat',
        hidden: true,
        component: () => import('@/views/us/s-t/gr-gm/gmat'),
      },
      {
        name: 'a24',
        path: '/a24',
        hidden: true,
        component: () => import('@/views/us/a-24'),
        meta: {
          title: '2024年秋季课程'
        }
      },
      {
        name: 'news',
        path: '/news',
        hidden: true,
        component: () => import('@/views/us/news'),
        meta: {
          title: '资讯'
        }
      },
      {
        name: 'news_detail',
        path: '/news/detail',
        hidden: true,
        component: () => import('@/views/us/news/detail'),
        meta: {
          title: '资讯详情'
        }
      },
      {
        name: 'exam',
        path: '/exam',
        hidden: true,
        component: () => import('@/views/us/exam'),
        meta: {
          title: '测验'
        }
      },
      {
        name: 'about_us',
        path: '/aboutus',
        hidden: true,
        component: () => import('@/views/us/about-us'),
        meta: {
          title: '关于我们'
        }
      },
      {
        name: 'contact_us',
        path: '/contactus',
        hidden: true,
        component: () => import('@/views/us/contact-us'),
        meta: {
          title: '联系我们'
        }
      },
      {
        name: 'online_consult',
        path: '/consult',
        hidden: true,
        component: () => import('@/views/us/contact-us/consult'),
        meta: {
          title: '在线咨询'
        }
      },
      {
        name: 'join_us',
        path: '/joinus',
        hidden: true,
        component: () => import('@/views/us/join-us'),
        meta: {
          title: '加入我们'
        }
      },
      {
        name: 'job_opening',
        path: '/jobs',
        hidden: true,
        component: () => import('@/views/us/join-us/job-opening'),
        meta: {
          title: '职位详情'
        }
      },
      {
        name: 'job_f',
        path: '/jobform',
        hidden: true,
        component: () => import('@/views/us/join-us/job-form'),
      },
      {
        name: 'job_1',
        path: '/job1',
        hidden: true,
        component: () => import('@/views/us/join-us/job-1'),
      },
      {
        name: 'job_2',
        path: '/job2',
        hidden: true,
        component: () => import('@/views/us/join-us/job-2'),
      },
      {
        name: 'job_3',
        path: '/job3',
        hidden: true,
        component: () => import('@/views/us/join-us/job-3'),
      },
      {
        name: 'job_4',
        path: '/job4',
        hidden: true,
        component: () => import('@/views/us/join-us/job-4'),
      },
      {
        name: 'job_5',
        path: '/job5',
        hidden: true,
        component: () => import('@/views/us/join-us/job-5'),
      },
      {
        name: 'account',
        path: '/account',
        hidden: true,
        component: () => import('@/views/us/account'),
        meta: {
          title: '个人中心'
        }
      },
      {
        name: 'subject',
        path: '/subject',
        hidden: true,
        component: () => import('@/views/us/subject'),
        meta: {
          title: '学科'
        }
      },
      {
        name: 'exam_paper',
        path: '/exam_paper',
        hidden: true,
        component: () => import('@/views/us/exam-paper'),
        meta: {
          title: '测验'
        }
      },
    ]
  },
  {
    path: '/ad',
    component: adLayout,
    children: [
      {
        name: 'manage_subject_cate',
        path: '/ad/subject_cate',
        component: () => import('@/views/subject-cate'),
        meta: {
          title: '学科分类'
        }
      },
      {
        name: 'manage_subject',
        path: '/ad/subject',
        component: () => import('@/views/subject'),
        meta: {
          title: '学科管理'
        }
      },
      {
        name: 'manage_exam_paper',
        path: '/ad/exam_paper',
        component: () => import('@/views/exam-paper'),
        meta: {
          title: '试卷管理'
        }
      },
      {
        name: 'manage_news',
        path: '/ad/news',
        component: () => import('@/views/news'),
        meta: {
          title: '资讯管理'
        }
      },
      {
        name: 'manage_consult',
        path: '/ad/consult',
        component: () => import('@/views/consult'),
        meta: {
          title: '在线咨询'
        }
      },
      {
        name: 'job_apply',
        path: '/ad/job_apply',
        component: () => import('@/views/job-apply'),
        meta: {
          title: '申请简历'
        }
      },
    ]
  }
]


const createRouter = () => new Router({
  mode: 'history',
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes
})

const router = createRouter()

export default router
