import user from './user'
import subject from './subject'
import subject_cate from './subject_cate'
import exam from './exam'
import exam_section from './exam_section'
import exam_question from './exam_question'
import user_test from './user_test'
import news from './news'
import consult from './consult'
import job from './job'

export default Object.assign({},
  user,
  subject_cate,
  subject,
  exam,
  exam_section,
  exam_question,
  user_test,
  news,
  consult,
  job,
)
