import {
  jobApplyList,
  jobApplyAdd,
  jobApplyStatus,
} from '@/api/job'

export default {
  async jobApplyList({commit}, data) {
    return jobApplyList(data)
  },
  async jobApplyAdd({ }, data) {
    return jobApplyAdd(data)
  },
  async jobApplyStatus({ }, data) {
    return jobApplyStatus(data)
  },
}
