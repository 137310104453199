import service from '@/utils/request'

export function subjectList(data) {
  return service({
    url: '/api/subject/list',
    method: 'post',
    data
  })
}

export function subjectAdd(data) {
  return service({
    url: '/api/admin/subject/add',
    method: 'post',
    data
  })
}

export function subjectEdit(data) {
  return service({
    url: '/api/admin/subject/edit',
    method: 'post',
    data
  })
}

export function subjectStatus(data) {
  return service({
    url: '/api/admin/subject/status',
    method: 'post',
    data
  })
}

