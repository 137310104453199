import {
  consultList,
  consultAdd,
  consultStatus,
} from '@/api/consult'

export default {
  async consultList({commit}, data) {
    return consultList(data)
  },
  async consultAdd({ }, data) {
    return consultAdd(data)
  },
  async consultStatus({ }, data) {
    return consultStatus(data)
  },
}
