import {
  examQuestionList,
  examQuestionAdd,
  examQuestionEdit,
  examQuestionStatus,
  examQuestionDetail,
} from '@/api/exam_question'

export default {
  async examQuestionList({ }, data) {
    return examQuestionList(data)
  },
  async examQuestionAdd({ }, data) {
    return examQuestionAdd(data)
  },
  async examQuestionEdit({ }, data) {
    return examQuestionEdit(data)
  },
  async examQuestionStatus({ }, data) {
    return examQuestionStatus(data)
  },
  async examQuestionDetail({ }, data) {
    return examQuestionDetail(data)
  },
}
