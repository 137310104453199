import service from '@/utils/request'

export function newsList(data) {
  return service({
    url: '/api/news/list',
    method: 'post',
    data
  })
}

export function newsAdd(data) {
  return service({
    url: '/api/news/add',
    method: 'post',
    data
  })
}

export function newsStatus(data) {
  return service({
    url: '/api/news/status',
    method: 'post',
    data
  })
}

