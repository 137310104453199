import {
  subjectList,
  subjectAdd,
  subjectEdit,
  subjectStatus,
} from '@/api/subject'

export default {
  async subjectList({commit}, data) {
    const ret = await subjectList(data)
    if (ret.code === 0) {
      commit('SET_SUBJECT', ret.data.list)
    }
    return ret
  },
  async subjectAdd({ }, data) {
    return subjectAdd(data)
  },
  async subjectEdit({ }, data) {
    return subjectEdit(data)
  },
  async subjectStatus({ }, data) {
    return subjectStatus(data)
  },
}
