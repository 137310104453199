import router, { permissionRoutes } from './router'
import store from './store'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { setToken } from '@/utils/auth'
// import getPageTitle from '@/utils/get-page-title'

NProgress.configure({ showSpinner: false })

// 递归遍历路由
const getRecursionRoute = (list, cacheRoutes) => {
  for (const item of list) {
    if (item.children && item.children.length > 0) {
      getRecursionRoute(item.children, cacheRoutes)
    } else {
      for (const sub of cacheRoutes) {
        if (item.name === sub.page_key) {
          item.status = sub.page_status
          break
        }
      }
    }
  }
}

// 创建授权路由
const createAccessRoutes = async() => {
  let cacheRoutes = store.getters.accessRoutes
  return cacheRoutes
}

createAccessRoutes().then(async (cacheRoutes) => {
  await store.dispatch('userInfo')
  getRecursionRoute(permissionRoutes, cacheRoutes)
  store.commit('CACHE_ALL_ROUTES', permissionRoutes)
  router.addRoutes(permissionRoutes)
  router.addRoutes([{ path: '*', redirect: '/404', hidden: true }])
})

router.beforeEach(async(to, from, next) => {
  // 管理页面保护
  if (to.path.indexOf('/ad') >= 0) {
    let role = store.getters.getUserRole
    if (!role) {
      await store.dispatch('userInfo')
    }
    role = store.getters.getUserRole
    if (role != 1) {
      // window.location.href = '/home'
      router.push('/home')
      return
    }
  }
  // 页面切换进度条
  NProgress.start()

  // 设置页面标题
  // document.title = getPageTitle(to.meta.title)
  next()
})

router.afterEach(() => {
  // 关闭进度提示
  NProgress.done()
})
