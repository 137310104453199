import service from '@/utils/request'

export function userLogin(data) {
  return service({
    url: '/api/user/login',
    method: 'post',
    data
  })
}

export function userRegister(data) {
  return service({
    url: '/api/user/register',
    method: 'post',
    data
  })
}

export function userEdit(data) {
  return service({
    url: '/api/user/edit',
    method: 'post',
    data
  })
}

export function userPwd(data) {
  return service({
    url: '/api/user/pwd',
    method: 'post',
    data
  })
}

export function userInfo(data) {
  return service({
    url: '/api/user/info',
    method: 'post',
    data
  })
}

export function userPhoneVerify(data) {
  return service({
    url: '/api/user/phone/verify',
    method: 'post',
    data
  })
}


export function adminUploadImage(data) {
  return service({
    url: '/api/admin/upload/image',
    method: 'post',
    data
  })
}