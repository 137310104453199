import service from '@/utils/request'

export function examList(data) {
  return service({
    url: '/api/exam/list',
    method: 'post',
    data
  })
}

export function examAdd(data) {
  return service({
    url: '/api/admin/exam/add',
    method: 'post',
    data
  })
}

export function examEdit(data) {
  return service({
    url: '/api/admin/exam/edit',
    method: 'post',
    data
  })
}

export function examStatus(data) {
  return service({
    url: '/api/admin/exam/status',
    method: 'post',
    data
  })
}

export function examDetail(data) {
  return service({
    url: '/api/exam/detail',
    method: 'post',
    data
  })
}

export function fileTypeList(data) {
  return service({
    url: '/api/file_type/list',
    method: 'post',
    data
  })
}

export function fileTypeAdd(data) {
  return service({
    url: '/api/admin/file_type/add',
    method: 'post',
    data
  })
}

export function examFileList(data) {
  return service({
    url: '/api/exam/file/list',
    method: 'post',
    data
  })
}

export function examFileAdd(data) {
  return service({
    url: '/api/admin/exam/file/add',
    method: 'post',
    data
  })
}

export function examFileDelete(data) {
  return service({
    url: '/api/admin/exam/file/delete',
    method: 'post',
    data
  })
}
