import service from '@/utils/request'

export function consultList(data) {
  return service({
    url: '/api/consult/list',
    method: 'post',
    data
  })
}

export function consultAdd(data) {
  return service({
    url: '/api/consult/add',
    method: 'post',
    data
  })
}

export function consultStatus(data) {
  return service({
    url: '/api/consult/status',
    method: 'post',
    data
  })
}

