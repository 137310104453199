import {
  examList,
  examAdd,
  examEdit,
  examStatus,
  examDetail,
  fileTypeList,
  fileTypeAdd,
  examFileList,
  examFileAdd,
  examFileDelete,
} from '@/api/exam'

export default {
  async examList({ }, data) {
    return examList(data)
  },
  async examAdd({ }, data) {
    return examAdd(data)
  },
  async examEdit({ }, data) {
    return examEdit(data)
  },
  async examStatus({ }, data) {
    return examStatus(data)
  },
  async examDetail({ }, data) {
    return examDetail(data)
  },
  async fileTypeList({ commit }, data) {
    const ret = await fileTypeList()
    if (ret.code === 0) {
      commit('SET_FILE_TYPE', ret.data)
    }
    return ret
  },
  async fileTypeAdd({ }, data) {
    return fileTypeAdd(data)
  },
  async examFileList({ }, data) {
    return examFileList(data)
  },
  async examFileAdd({ }, data) {
    return examFileAdd(data)
  },
  async examFileDelete({ }, data) {
    return examFileDelete(data)
  },
}
