<template>
  <div class="us-footer-wrap">
    <div class="footer-logo" @click="onGo('/home')">
      <img src="@/assets/logo2.png">
    </div>
    <div class="info-wrap">
      <div class="info-col" style="padding-left: 40px;">
        <div class="info-title">{{ $t("home.footer.ACADEMIC_OFFERINGS") }}</div>
        <div class="info-item" @click="onGo('/ic')">{{ $t("menu.international_curricula") }}</div>
        <div class="info-item" @click="onGo('/dc')">{{ $t("menu.discipline_competition") }}</div>
        <div class="info-item" @click="onGo('/st')">{{ $t("menu.standardized_tests") }}</div>
      </div>
      <div class="info-col" style="padding-left: 70px;">
        <div class="info-title">{{ $t("home.footer.CONSULTING") }}</div>
        <div class="info-item" @click="onGo('/consult')">{{ $t("menu.Online_Consult") }}</div>
        <div class="info-title" style="margin-top: 20px;">{{ $t("home.footer.about_us") }}</div>
        <div class="info-item" @click="onGo('/aboutus#senior_lecturer')">{{ $t("home.footer.Senior_Lecturer") }}</div>
        <div class="info-item" @click="onGo('/aboutus#teachers')">{{ $t("home.footer.Teachers") }}</div>
        <div class="info-item" @click="onGo('/aboutus#achievement')">{{ $t("home.footer.Achievement") }}</div>
      </div>
      <div class="info-col">
        <img class="footer-logo-img" src="/static/footLogo1.png" alt="">
        <div v-if="getLang === 'ZH'" class="address">
          <div class="address-row">广州天河区天河北路233号中信广场38层</div>
        </div>
        <div v-else class="address">
          <div class="address-row">38th Floor, Hao CITIC Plaza,</div>
          <div class="address-row">233 Tianhe North Road,</div>
          <div class="address-row">Tianhe District, Guangzhou</div>
        </div>
        <div class="info-row">
          <div class="label">TEL:</div>
          <div class="value">180-2880-0860</div>
        </div>
        <div class="info-row">
          <div class="label">Email:</div>
          <div class="value">chido public@outlook.com</div>
        </div>
      </div>
    </div>
    <div class="copyright-wrap">
      <SysCopyright></SysCopyright>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import SysCopyright from '_c/sys-copyright'

export default {
  name: 'Footer',
  components: {
    SysCopyright
  },
  computed: {
    ...mapGetters([
      'getLang',
    ]),
  },
  methods: {
    onGo(tag) {
      if (this.$route.path != tag) {
        if (!tag.includes("#")) {
          this.$router.push(tag)
        } else {
          const [page, target] = tag.split("#");
          if (this.$route.path != page) {
            this.$router.push(tag)
          } else {
            let section = document.getElementById(target)
            if (section) {
              section.scrollIntoView()
            }
          }
        }
      }
    },
  }
}
</script>

<style lang="less" scoped>
.copyright-wrap {
  margin: 10px;
  text-align: center;
}
.us-footer-wrap {
  width: 100%;
  // height: 390px;
  overflow: hidden;
  background-color: #414042;
  color: #ffffff;
  font-size: 15px;
  // padding: 50px;
  .footer-logo {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 50px;
    cursor: pointer;
    img {
      width: 200px;
    }
  }
}
.info-wrap {
  width: 100%;
  padding-bottom: 50px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 40px;
  .info-col {
    width: 300px;
    line-height: 2;
    .info-title {
      font-size: 20px;
    }
    .info-item {
      cursor: pointer;
      font-size: 15px;
      position: relative;
      padding-left: 10px;
      &::before {
        content: "";
        width: 4px;
        height: 4px;
        background-color: #fff;
        position: absolute;
        left: 0;
        top: 13px;
      }
      &:hover {
        color: rgba(255, 255, 255, .8);
      }
    }
  }
}
.footer-logo-img {
  width: 200px;
}
.info-row {
  display: flex;
  align-items: center;
  .label {
    width: 60px;
    flex-shrink: 0;
  }
}
</style>
