import service from '@/utils/request'

export function subjectCateList(data) {
  return service({
    url: '/api/cate/list',
    method: 'post',
    data
  })
}

export function subjectCateAdd(data) {
  return service({
    url: '/api/admin/cate/add',
    method: 'post',
    data
  })
}

export function subjectCateEdit(data) {
  return service({
    url: '/api/admin/cate/edit',
    method: 'post',
    data
  })
}

export function subjectCateStatus(data) {
  return service({
    url: '/api/admin/cate/status',
    method: 'post',
    data
  })
}