<template>
  <div class="us-nav-wrap">
    <div class="us-nav-inner">
      <div class="navbar-left" @click="onGoHome">
        <img src="@/assets/logo.png">
        <div class="sys-title">{{ $t('home.system_name') }}</div>
      </div>
      <div class="navbar-right">
        <Menu />
        <div v-if="!getUserName" class="right-item" @click="onLogin">{{ $t('home.login') }}</div>
        <template v-else>
          <div class="right-item" @click="onToAccount">{{ getUserName }}</div>
          <el-tooltip class="item" effect="dark" :content="$t('home.logout')" placement="bottom">
            <div class="right-item" @click="onLoginOut">
              <i class="el-icon-close"></i>
            </div>
          </el-tooltip>
        </template>
        <div class="line"></div>
        <div class="lang-wrap">
          <div :class="{ actived: language === 'EN' }" class="lang-item" @click="onChangeLang('EN')">EN</div>
          <div :class="{ actived: language === 'ZH' }" class="lang-item" @click="onChangeLang('ZH')">中</div>
        </div>
      </div>
    </div>
    <login-form ref="loginForm" />
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import LoginForm from '_c/login-form'
import Menu from './Menu'

export default {
  name: 'nav-bar',
  components: { LoginForm, Menu },
  data() {
    return {
      title: '炽道国际教育',
      showTheme: false,
      language: "ZH"
    }
  },
  computed: {
    ...mapGetters([
      'getUserName',
    ]),
  },
  mounted() {
    const lang = localStorage.getItem("chidao_lan_locale") || "ZH";
    this.onChangeLang(lang)
  },
  methods: {
    ...mapActions({
      actionSetLang: 'setLang',
      actionLoginOut: 'userLogout'
    }),
    ...mapActions({
      setLang: 'SET_LANG'
    }),
    onChangeLang(lang) {
      localStorage.setItem("chidao_lan_locale", lang);
      this.$i18n.locale = lang;
      this.language = lang;
      this.actionSetLang(lang);
    },
    onToAccount() {
      if (this.$route.name != 'account') {
        this.$router.push('/account')
      }
    },
    onGoHome() {
      if (this.$route.name != 'home') {
        this.$router.push('/home')
      }
    },
    onLogin() {
      this.$refs.loginForm.show()
    },
    onLoginOut() {
      this.$confirm('确定要退出当前账号吗?', '退出账号', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal: false,
        type: 'warning'
      }).then(async () => {
        const ret = await this.actionLoginOut()
        if (ret.code === 0) {
          // window.location.href = `/home`
          this.$router.push('/home')
        }
      }).catch(() => { })
    }
  }
}
</script>

<style lang="less" scoped>
@barHeight: 70px;
@pageWidth: 1600px;
@splitColor: #eee;

.us-nav-wrap {
  width: 100%;
  background-color: rgba(255, 255, 255, 1);
  position: fixed;
  top: 0;
  z-index: 999;
  color: @fontColorMain;
  font-size: 15px;

  .us-nav-inner {
    width: auto;
    max-width: @pageWidth;
    min-width: 1290px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: @barHeight;
    color: #333;

    .navbar-left {
      cursor: pointer;
      img {
        height: 40px;
      }
      .sys-title {
        font-weight: 600;
        // letter-spacing: 2px;
        color: #233568;
        margin-left: 4px;
        line-height: 1;
      }
    }

    .navbar-right {
      display: flex;
      align-items: center;
      height: 100%;

      .right-item {
        height: 100%;
        display: flex;
        align-items: center;
        padding: 0 10px;
        font-size: 14px;
        cursor: pointer;

        &:hover {
          background-color: @splitColor;
        }
      }

      .line {
        height: 40px;
        width: 2px;
        margin: 0 10px;
        background-color: @themeColor1;
      }

      .lang-wrap {
        display: flex;
        align-items: center;
        gap: 10px;
      }

      .lang-item {
        color: @fontColorMain;
        cursor: pointer;
        user-select: none;

        &.actived {
          color: @themeColor1;
          font-weight: 600;
        }
      }
    }
  }
}
</style>
