import service from '@/utils/request'

export function jobApplyList(data) {
  return service({
    url: '/api/job_apply/list',
    method: 'post',
    data
  })
}

export function jobApplyAdd(data) {
  return service({
    url: '/api/job_apply/add',
    method: 'post',
    data
  })
}

export function jobApplyStatus(data) {
  return service({
    url: '/api/job_apply/status',
    method: 'post',
    data
  })
}

