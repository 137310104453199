<template>
  <div class="menu-wrap">
    <!-- 国际课程 -->
    <div class="menu-item have-more">
      <el-popover
        placement="bottom"
        width="230"
        trigger="hover">
        <div class="menu-sub-warp">
          <div class="menu-sub-item" @click="onGo('/apib')">AP/IB</div>
          <div class="menu-sub-item" @click="onGo('/igal')">IGCSE/A-Level</div>
          <div class="menu-sub-item" @click="onGo('/hkdse')">HKDSE</div>
        </div>
        <div class="menu-title" slot="reference" @click="onGo('/ic')">{{ $t('menu.international_curricula') }}</div>
      </el-popover>
    </div>
    <!-- 竞赛学科 -->
    <div class="menu-item have-more">
      <el-popover
        placement="bottom"
        width="230"
        trigger="hover">
        <div class="menu-sub-warp">
          <div class="menu-sub-item" @click="onGo('/sat')">{{ $t('menu.Science_and_Technology') }}</div>
          <div class="menu-sub-item" @click="onGo('/aal')">{{ $t('menu.Arts_and_Languages') }}</div>
          <div class="menu-sub-item" @click="onGo('/sse')">{{ $t('menu.Social_Sciences_And_Economics') }}</div>
        </div>
        <div class="menu-title" slot="reference" @click="onGo('/dc')">{{ $t('menu.discipline_competition') }}</div>
      </el-popover>
    </div>
    <!-- 标化测试 -->
    <div class="menu-item have-more">
      <el-popover
        placement="bottom"
        width="230"
        trigger="hover">
        <div class="menu-sub-warp">
          <div class="menu-sub-item" @click="onGo('/ieto')">IELTS_TOEFL</div>
          <div class="menu-sub-item" @click="onGo('/satact')">SAT_ACT</div>
          <div class="menu-sub-item" @click="onGo('/grgm')">GRE_GMAT</div>
        </div>
        <div class="menu-title" slot="reference" @click="onGo('/st')">{{ $t('menu.standardized_tests') }}</div>
      </el-popover>
    </div>
    <!-- 资源中心 -->
    <div class="menu-item have-more">
      <el-popover
        placement="bottom"
        width="230"
        trigger="hover">
        <div class="menu-sub-warp">
          <div class="menu-sub-item" @click="onGo('/news')">{{ $t('menu.News') }}</div>
          <div class="menu-sub-item" @click="onGo('/exam')">{{ $t('menu.simulation_test') }}</div>
        </div>
        <div class="menu-title" slot="reference" @click="onGo('/news')">{{ $t('menu.resource') }}</div>
      </el-popover>
    </div>
    <!-- 关于我们 -->
    <div class="menu-item">
      <div class="menu-title" @click="onGo('/aboutus')">{{ $t('menu.about_us') }}</div>
    </div>
    <!-- 联系我们 -->
    <div class="menu-item have-more">
      <el-popover
        placement="bottom"
        width="230"
        trigger="hover">
        <div class="menu-sub-warp">
          <div class="menu-sub-item" @click="onGo('/contactus')">{{ $t('menu.Contact_Way') }}</div>
          <div class="menu-sub-item" @click="onGo('/consult')">{{ $t('menu.Online_Consult') }}</div>
          <div class="menu-sub-item" @click="onGo('/joinus')">{{ $t('menu.Join_Us') }}</div>
        </div>
        <div class="menu-title" slot="reference" @click="onGo('/contactus')">{{ $t('menu.contact_us') }}</div>
      </el-popover>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'munu',
  computed: {
    ...mapGetters([
      'getUserName',
    ]),
  },
  methods: {
    onGoHome() {
      if (this.$route.name != 'home') {
        this.$router.push('/home')
      }
    },
    onGo(tag) {
      if (this.$route.path != tag) {
        this.$router.push(tag)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.menu-wrap {
  margin: 0px 20px;
  display: flex;
  align-items: center;
  .menu-item {
    position: relative;
    padding-right: 18px;
    margin-right: 15px;
    font-weight: 900;
    color: @fontColorMain;
    cursor: pointer;
    user-select: none;
    &.actived {
      color: @themeColor1;
    }
    :hover {
      color: @themeColor1;
    }
    &.have-more {
      ::after {
        content: '';
        position: absolute;
        top: 80%;
        right: 7px;
        margin-top: -7.5px;
        border: solid @themeColor1;
        border-width: 0 1px 1px 0;
        padding: 2.5px;
        transition: all 0.2s ease 0s;
        transform: translateY(-50%) rotate(45deg);
        -webkit-transform: translateY(-50%) rotate(45deg);
      }
    }
  }
}
.menu-sub-warp {
  .menu-sub-item {
    font-weight: 900;
    color: @fontColorMain;
    cursor: pointer;
    user-select: none;
    line-height: 2;
    &:hover {
      color: @themeColor1;
    }

  }
}
</style>
