import service from '@/utils/request'

export function examQuestionList(data) {
  return service({
    url: '/api/exam_question/list',
    method: 'post',
    data
  })
}

export function examQuestionAdd(data) {
  return service({
    url: '/api/admin/exam_question/add',
    method: 'post',
    data
  })
}

export function examQuestionEdit(data) {
  return service({
    url: '/api/admin/exam_question/edit',
    method: 'post',
    data
  })
}

export function examQuestionStatus(data) {
  return service({
    url: '/api/admin/exam_question/status',
    method: 'post',
    data
  })
}

export function examQuestionDetail(data) {
  return service({
    url: '/api/exam_question/detail',
    method: 'post',
    data
  })
}
