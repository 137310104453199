import service from '@/utils/request'

export function examSectionList(data) {
  return service({
    url: '/api/exam_section/list',
    method: 'post',
    data
  })
}

export function examSectionAdd(data) {
  return service({
    url: '/api/admin/exam_section/add',
    method: 'post',
    data
  })
}

export function examSectionEdit(data) {
  return service({
    url: '/api/admin/exam_section/edit',
    method: 'post',
    data
  })
}

export function examSectionStatus(data) {
  return service({
    url: '/api/admin/exam_section/status',
    method: 'post',
    data
  })
}

