import {
  subjectCateList,
  subjectCateAdd,
  subjectCateEdit,
  subjectCateStatus,
} from '@/api/subject_cate'

export default {
  async subjectCateList({commit}, data) {
    const ret = await subjectCateList(data)
    if (ret.code === 0) {
      commit('SET_SUBJECT_CATE', ret.data.list)
    }
    return ret
  },
  async subjectCateAdd({ }, data) {
    return subjectCateAdd(data)
  },
  async subjectCateEdit({ }, data) {
    return subjectCateEdit(data)
  },
  async subjectCateStatus({ }, data) {
    return subjectCateStatus(data)
  },
}
