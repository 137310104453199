import {
  examSectionList,
  examSectionAdd,
  examSectionEdit,
  examSectionStatus,
} from '@/api/exam_section'

export default {
  async examSectionList({ }, data) {
    return examSectionList(data)
  },
  async examSectionAdd({ }, data) {
    return examSectionAdd(data)
  },
  async examSectionEdit({ }, data) {
    return examSectionEdit(data)
  },
  async examSectionStatus({ }, data) {
    return examSectionStatus(data)
  },
}