<template>
  <div>
    <el-menu
      :default-active="currentRoute"
      background-color="#304156"
      text-color="#bfcbd9"
      :unique-opened="true"
      active-text-color="#409EFF"
      mode="vertical"
      @select="onSelect">
      <div v-for="item in allRoutes" :key="item.name">
        <div v-if="!item.hidden">
          <el-submenu v-if="item.children && item.children.filter(i=>i.status===1).length > 0" :index="item.path">
            <template slot="title">
              <i :class="item.meta.icon"/>
              <span slot="title">{{ item.meta.title }}</span>
            </template>
            <el-menu-item v-for="sub in item.children.filter(i=>i.status===1)" :key="sub.name" :index="sub.path">
              <i :class="sub.meta.icon"/>
              <span slot="title">{{ sub.meta.title }}</span>
            </el-menu-item>
          </el-submenu>
          <div v-else>
            <el-menu-item :index="item.path">
              <i :class="item.meta.icon"/>
              <span slot="title">{{ item.meta.title }}</span>
            </el-menu-item>
          </div>
        </div>
      </div>
    </el-menu>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import variables from '@/styles/variables.less'

export default {
  name: 'side-bar',
  computed: {
    ...mapGetters([
      'getAllRoutes'
    ]),
    variables() {
      return variables
    },
    currentRoute() {
      return this.$route.path
    },
    allRoutes() {
      let list = []
      for (const item of this.getAllRoutes) {
        if (item.children && item.children.length > 0) {
          list = list.concat(item.children)
        } else {
          list.push(item)
        }
      }
      console.log(list)
      return list.filter(item => !item.hidden)
    }
  },
  methods: {
    onSelect(index) {
      if (this.currentRoute !== index) {
        this.$router.push(index)
      }
    }
  }
}
</script>
