import {
  newsList,
  newsAdd,
  newsStatus,
} from '@/api/news'

export default {
  async newsList({commit}, data) {
    return newsList(data)
  },
  async newsAdd({ }, data) {
    return newsAdd(data)
  },
  async newsStatus({ }, data) {
    return newsStatus(data)
  },
}
