<template>
  <el-dialog 
    :visible.sync="dialogVisible" 
    :close-on-click-modal="false" 
    append-to-body
    width="360px"
    custom-class="login-form-dialog">
    <template v-if="lost_password">
      <h2 style="text-align: center;">找回密码</h2>
      <el-form ref="lostForm" :rules="lostRules" :model="lostData">
        <el-form-item prop="phone">
          <el-input 
            v-model="lostData.phone" 
            prefix-icon="el-icon-phone"
            placeholder="请输入手机号码" 
            maxlength="80"
            @keyup.native.enter="handleLost">
            <el-button slot="append" v-loading="verifyLoading" @click="onPhoneVerify">
              <span v-if="verifyTimes">{{ verifyTimes }}</span>
              <span v-else>发送验证码</span>
            </el-button>
          </el-input>
        </el-form-item>
        <el-form-item prop="verify_code">
          <el-input 
            v-model="lostData.verify_code" 
            prefix-icon="el-icon-key"
            placeholder="请输入验证码" 
            maxlength="80"
            @keyup.native.enter="handleLost"/>
        </el-form-item>
      </el-form>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-button plain style="width: 100%;" @click="lost_password = false">返回</el-button>
        </el-col>
        <el-col :span="12">
          <el-button type="primary" style="width: 100%;" @click="handleLost">验证账户</el-button>
        </el-col>
      </el-row>
    </template>
    <template v-else>
      <div class="login-form-tabs">
        <el-tabs v-model="activeName" stretch>
          <el-tab-pane label="登录" name="login"></el-tab-pane>
          <el-tab-pane label="注册" name="register"></el-tab-pane>
        </el-tabs>
      </div>
      <template v-if="activeName === 'login'">
        <el-form ref="loginForm" :rules="loginRules" :model="loginData">
          <el-form-item prop="phone">
            <el-input 
              v-model="loginData.phone" 
              prefix-icon="el-icon-phone"
              placeholder="请输入手机号码" 
              maxlength="80"
              @keyup.native.enter="handleLogin"/>
          </el-form-item>
          <el-form-item prop="password">
            <el-input
              ref="password"
              v-model="loginData.password"
              placeholder="请输入密码"
              name="password"
              type="password"
              prefix-icon="el-icon-lock"
              maxlength="20"
              @keyup.native.enter="handleLogin"/>
          </el-form-item>
        </el-form>
        <div class="btn-wrap">
          <el-button type="primary" style="width: 200px;" @click="handleLogin">登录</el-button>
        </div>
        <div style="text-align: right;">
          <el-button type="text" @click="lost_password = true">忘记密码</el-button>
        </div>
      </template>
      <template v-else>
        <el-form ref="regForm" :rules="regRules" :model="regData">
          <el-form-item prop="phone">
            <el-input 
              v-model="regData.phone" 
              prefix-icon="el-icon-phone"
              placeholder="请输入手机号码" 
              maxlength="80"
              @keyup.native.enter="onReg">
              <el-button slot="append" v-loading="verifyLoading" @click="onPhoneVerify">
                <span v-if="verifyTimes">{{ verifyTimes }}</span>
                <span v-else>发送验证码</span>
              </el-button>
            </el-input>
          </el-form-item>
          <el-form-item prop="verify_code">
            <el-input 
              v-model="regData.verify_code" 
              prefix-icon="el-icon-key"
              placeholder="请输入验证码" 
              maxlength="80"
              @keyup.native.enter="onReg"/>
          </el-form-item>
          <el-form-item prop="name">
            <el-input 
              v-model="regData.name" 
              prefix-icon="el-icon-user"
              placeholder="请输入用户名称" 
              maxlength="80"
              @keyup.native.enter="onReg"/>
          </el-form-item>
          <el-form-item prop="password">
            <el-input
              v-model="regData.password"
              placeholder="请输入密码"
              type="password"
              prefix-icon="el-icon-lock"
              maxlength="20"
              @keyup.native.enter="onReg"/>
          </el-form-item>
          <el-form-item prop="rep_password">
            <el-input
              v-model="regData.rep_password"
              placeholder="请输入密码确认"
              type="password"
              prefix-icon="el-icon-lock"
              maxlength="20"
              @keyup.native.enter="onReg"/>
          </el-form-item>
        </el-form>
        <div class="btn-wrap">
          <el-button type="primary" style="width: 200px;" @click="onReg">注册</el-button>
        </div>
      </template>
    </template>
  </el-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import md5 from 'md5'
import { setToken } from '@/utils/auth'

export default {
  data() {
    return {
      dialogVisible: false,
      activeName: 'login',
      lost_password: false,
      loginData: {
        phone: '',
        password: '',
      },
      loginRules: {
        phone: [
          { required: true, message: '请输入手机号码', trigger: 'blur' }, 
          { validator: function(rule , value, callback) {
            if(/^1[34578]\d{9}$/.test(value) == false){
              callback(new Error("请输入正确的手机号"));
            } else {
              callback();
            }
          }, trigger: 'blur'}
        ],
        password: [{ required: true, message: '密码不能为空', trigger: 'blur' }],
      },
      regData: {
        verify_code: '',
        name: '',
        phone: '',
        password: '',
        rep_password: '',
      },
      regRules: {
        name: [{ required: true, message: '名称不能为空', trigger: 'blur' }],
        phone: [
          { required: true, message: '请输入手机号码', trigger: 'blur' }, 
          { validator: function(rule , value, callback) {
            if(/^1[34578]\d{9}$/.test(value) == false){
              callback(new Error("请输入正确的手机号"));
            } else {
              callback();
            }
          }, trigger: 'blur'}
        ],
        verify_code: [{ required: true, message: '验证码不能为空', trigger: 'blur' }],
        password: [{ required: true, message: '密码不能为空', trigger: 'blur' }],
        rep_password: [
          { required: true, message: '确认密码不能为空', trigger: 'blur' },
          { message: '确认密码不一致', trigger: 'blur', validator: this.ConfirmPassword }
        ],
      },
      lostData: {
        phone: '',
        verify_code: '',
      },
      lostRules: {
        phone: [
          { required: true, message: '请输入手机号码', trigger: 'blur' }, 
          { validator: function(rule , value, callback) {
            if(/^1[34578]\d{9}$/.test(value) == false){
              callback(new Error("请输入正确的手机号"));
            } else {
              callback();
            }
          }, trigger: 'blur'}
        ],
        verify_code: [{ required: true, message: '验证码不能为空', trigger: 'blur' }],
      },

      // 验证码倒计时
      verifyLoading: false,
      verifyStartTime: 0,
      verifyTimes: 0,
      verifyTimer: null
    }
  },
  computed: {
    ...mapGetters([
      'getSubjectCateList'
    ])
  },
  watch: {
    // dialogVisible() {
    //   if (!this.dialogVisible) {
    //     this.$refs.loginForm.resetFields()
    //     Object.assign(this.loginData, this.$options.data().loginData)
    //     this.$refs.regForm.resetFields()
    //     Object.assign(this.regData, this.$options.data().regData)
    //   }
    // },
    activeName() {
      this.$nextTick(() => {
        if (this.activeName === 'login') {
          this.$refs.loginForm.resetFields()
          Object.assign(this.loginData, this.$options.data().loginData)
        } else {
          this.$refs.regForm.resetFields()
          Object.assign(this.regData, this.$options.data().regData)
        }
      })
    }
  },
  beforeDestroy() {
    this.clearTimer()
  },
  methods: {
    ...mapActions({
      actionUserLogin: 'userLogin',
      actionUserPhoneVerify: 'userPhoneVerify',
      actionUserRegister: 'userRegister',
      actionUserInfo: 'userInfo',
    }),
    ConfirmPassword (rule, value, callback) {
      if (value !== this.regData.password) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    },
    async show() {
      this.dialogVisible = true
    },
    clearTimer() {
      if (this.verifyTimer || this.verifyLoading) {
        clearInterval(this.verifyTimer)
      }
      this.verifyTimer = null
      this.verifyTimes = 0
      this.verifyStartTime = 0
    },
    // 获取验证码
    async onPhoneVerify() {
      if (this.verifyTimer) {
        return
      }
      const phone = this.lost_password ? this.lostData.phone : this.regData.phone
      const type = this.lost_password ? 2 : 1
      if (!phone) {
        this.$message.warning('请输入手机号码')
        return
      }
      this.verifyLoading = true
      const ret = await this.actionUserPhoneVerify({
        phone,
        type
      })
      if (ret.code === 0) {
        this.verifyStartTime = new Date().getTime()
        this.verifyTimer = setInterval(() => {
          const verifyTimes = (new Date().getTime() - this.verifyStartTime)/1000
          if (this.verifyTimes < 0) {
            this.clearTimer()
          }
          this.verifyTimes = Math.max(0, Math.floor(240 -verifyTimes))
        }, 200);
      }
      this.verifyLoading = false
    },
    onReg() {
      this.$refs.regForm.validate(async valid => {
        if (valid) {
          const { verify_code, name, phone, password } = this.regData
          const ret = await this.actionUserRegister({
            verify_code,
            name, 
            phone,
            password: md5(password.trim())
          })
          if (ret.code === 0) {
            this.$message.success('注册成功')
            this.$refs.regForm.resetFields()
            Object.assign(this.regData, this.$options.data().regData)
            this.activeName = 'login'
          }
        }
      })
    },
    async doLogin() {
      const phone = this.lost_password ? this.lostData.phone.trim() : this.loginData.phone.trim()
      const password = this.lost_password ? null : md5(this.loginData.password.trim())
      const verify_code = this.lost_password ? this.lostData.verify_code.trim() : null
      const ret = await this.actionUserLogin({
        phone,
        password,
        verify_code,
      })
      if (ret.code === 0) {
        const { token, role } = ret.data
        setToken(token)
        this.actionUserInfo()
        this.dialogVisible = false
        if (role == 1) {
        this.$router.push('/ad')
        } else {
          window.location.reload()
        }
      }
    },
    async handleLogin() {
      this.$refs.loginForm.validate(async valid => {
        if (valid) {
          this.loading = true
          await this.doLogin()
          this.loading = false
        }
      })
    },
    async handleLost() {
      this.$refs.lostForm.validate(async valid => {
        if (valid) {
          this.loading = true
          await this.doLogin()
          this.loading = false
        }
      })
    },
  }
}
</script>
<style lang="less">
.login-form-tabs {
  width: 140px;
  margin: 0 auto;
  .el-tabs__item {
    padding: 0 !important;
  }
}
.login-form-dialog {
  border-radius: 12px;
  .el-dialog__header {
    padding: 0;
  }
}
</style>
<style lang="less" scoped>
.btn-wrap {
  width: 100%;
  display: flex;
  justify-content: center;
}
</style>
