import {
  userTestTestStart,
  userTestTestSubmit,
  userTestTestDelete,
  userTestTestDetail,
  userTestTestResult,
  userTestSectionStart,
  userTestSectionSubmit,
  userTestSectionDetail,
  userTestQuestionSubmit,
  userTestQuestionDetail,
} from '@/api/user_test'

export default {
  async userTestTestStart({ }, data) {
    return userTestTestStart(data)
  },
  async userTestTestSubmit({ }, data) {
    return userTestTestSubmit(data)
  },
  async userTestTestDelete({ }, data) {
    return userTestTestDelete(data)
  },
  async userTestTestDetail({ }, data) {
    return userTestTestDetail(data)
  },
  async userTestTestResult({ }, data) {
    return userTestTestResult(data)
  },
  async userTestSectionStart({ }, data) {
    return userTestSectionStart(data)
  },
  async userTestSectionSubmit({ }, data) {
    return userTestSectionSubmit(data)
  },
  async userTestSectionDetail({ }, data) {
    return userTestSectionDetail(data)
  },
  async userTestQuestionSubmit({ }, data) {
    return userTestQuestionSubmit(data)
  },
  async userTestQuestionDetail({ }, data) {
    return userTestQuestionDetail(data)
  },
}
