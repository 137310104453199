<template>
  <div class="app-main">
    <div class="app-main-inner">
      <transition name="fade-transform" mode="out-in">
        <router-view :key="key" />
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppMain',
  computed: {
    key() {
      return this.$route.path
    }
  }
}
</script>

<style lang="less" scoped>
.app-main {
  position: relative;
  overflow: hidden;

  .app-main-inner {
    height: 100%;
    overflow: auto;
    padding: 20px;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}
</style>
