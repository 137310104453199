<template>
  <div class="navbar-container">
    <div class="navbar-left">
      <h1 class="navbar-title" @click="onToHome">{{ title }}</h1>
      <component-breadcrumb />
    </div>
    <div class="navbar-right">
      <div class="right-item" @click="onToAccount">
        <span>{{ getUserName }}</span>
      </div>
      <el-tooltip class="item" effect="dark" content="退出当前账户" placement="bottom">
        <div class="right-item" @click="onLoginOut">
          <i class="el-icon-close"></i>
        </div>
      </el-tooltip>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ComponentBreadcrumb from './breadcrumb'

export default {
  name: 'nav-bar',
  components: { ComponentBreadcrumb },
  data() {
    return {
      title: '炽道国际教育',
    }
  },
  computed: {
    ...mapGetters([
      'getUserName',
    ]),
  },
  methods: {
    ...mapActions({
      actionLoginOut: 'userLogout'
    }),
    onToHome() {
      if (this.$route.name != 'home') {
        this.$router.push('/home')
      }
    },
    onToAccount() {
      if (this.$route.name != 'account') {
        this.$router.push('/account')
      }
    },
    onLoginOut() {
      this.$confirm('确定要退出当前账号吗?', '退出账号', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal: false,
        type: 'warning'
      }).then(async() => {
        const ret = await this.actionLoginOut()
        if (ret.code === 0) {
          this.$router.push('/home')
          // window.location.href = `/home`
        }
      }).catch(() => {})
    }
  }
}
</script>

<style lang="less" scoped>
@barHeight: 50px;
@splitColor: #2a2f32;

.flex {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.navbar-container {
  .flex;
  position: relative;
  z-index: 3;
  justify-content: space-between;
  height: @barHeight;
  background: #373d41;
  box-shadow: 0 1px 4px @splitColor;
  color: #fff;

  .navbar-title {
    margin: 0 10px 0 20px;
    font-size: 18px;
    cursor: pointer;
  }

  .navbar-left {
    .flex;
    height: 100%;

    .left-item {
      .flex;
      height: 100%;
      border-left: 1px solid @splitColor;
      border-right: 1px solid @splitColor;
      padding: 0 21px;
      cursor: pointer;

      &:hover {
        background-color: @splitColor;
      }

      .left-fold {
        font-size: 20px;
      }
    }
  }

  .navbar-right {
    .flex;
    height: 100%;

    .right-item {
      .flex;
      height: 100%;
      border-left: 1px solid @splitColor;
      padding: 0 10px;
      font-size: 14px;
      cursor: pointer;

      .iconfont {
        font-size: 20px;
      }
      &:hover {
        background-color: @splitColor;
      }
    }
  }
}
</style>
