import service from '@/utils/request'

export function userTestTestStart(data) {
  return service({
    url: '/api/user_test/test/start',
    method: 'post',
    data
  })
}

export function userTestTestSubmit(data) {
  return service({
    url: '/api/user_test/test/submit',
    method: 'post',
    data
  })
}

export function userTestTestDelete(data) {
  return service({
    url: '/api/user_test/test/delete',
    method: 'post',
    data
  })
}

export function userTestTestDetail(data) {
  return service({
    url: '/api/user_test/test/detail',
    method: 'post',
    data
  })
}

export function userTestTestResult(data) {
  return service({
    url: '/api/user_test/test/result',
    method: 'post',
    data
  })
}

export function userTestSectionStart(data) {
  return service({
    url: '/api/user_test/section/start',
    method: 'post',
    data
  })
}

export function userTestSectionSubmit(data) {
  return service({
    url: '/api/user_test/section/submit',
    method: 'post',
    data
  })
}

export function userTestSectionDetail(data) {
  return service({
    url: '/api/user_test/section/detail',
    method: 'post',
    data
  })
}
export function userTestQuestionSubmit(data) {
  return service({
    url: '/api/user_test/question/submit',
    method: 'post',
    data
  })
}

export function userTestQuestionDetail(data) {
  return service({
    url: '/api/user_test/question/detail',
    method: 'post',
    data
  })
}