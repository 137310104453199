const getters = {
  accessRoutes: state => {
    return state.accessRoutes
  },
  hasLogin: state => {
    return state.role > 0
  },
  getUserInfo: state => {
    return state.userInfo
  },
  getUserName: state => {
    return state.name
  },
  getUserRole: state => {
    return state.role
  },
  getAllRoutes: state => {
    return state.allRoutes
  },
  getAssessmentYearList: state => {
    return state.assessmentYearList
  },
  getAssessmentFullScore: state => {
    return state.assessmentFullScore
  },

  getSubjectCateList: state => {
    return state.subject_cate_list
  },
  getSubjectList: state => {
    return state.subject_list
  },

  getQuestionTypeList: state => {
    return state.question_type_list
  },
  getFileTypeList: state => {
    return state.file_type_list
  },

  getNewsTypeList: state => {
    return state.news_type_list
  },
  getLang: state => {
    return state.lang
  },


}
export default getters
